.PercentageArea {
  position: relative;
}
.PercentageArea > h1 {
  font-size: 35px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.PercentageArea > p {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  margin-top: 10px;
}

.percentage_bottom_area {
  margin-top: 6rem;
}
.percentage_bottom_area.extra_padding > * {
  padding-bottom: 3rem !important;
}
.percentage_bottom_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

/* ProbResultWrapper */
.ProbResultWrapper,
.MaintainResultWrapper {
  border: 1px solid #707070;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 3rem;
  margin-bottom: 14rem !important;
}
.ProbResultWrapper .head {
  display: grid;
  grid-template-columns: 150px 1fr 160px;
}
.MaintainResultWrapper .head,
.MaintainResultWrapper .body .row {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}
.ProbResultWrapper .head > * {
  font-size: 18px;
}
.ProbResultWrapper .head > *,
.ProbResultWrapper .body .row > *,
.MaintainResultWrapper .body .row > *,
.MaintainResultWrapper .head > * {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}
.ProbResultWrapper .body .row > *:first-child {
  font-weight: 400;
}
.ProbResultWrapper .head > * + *,
.ProbResultWrapper .body .row > * + *,
.MaintainResultWrapper .body .row > * + *,
.MaintainResultWrapper .head > * + * {
  border-left: 1px solid #707070;
}
.ProbResultWrapper .body .row {
  display: grid;
  border-top: 1px solid #707070;
  grid-template-columns: 150px repeat(18, 1fr) 160px;
}

.MaintainResultWrapper .body .row {
  border-top: 1px solid #707070;
}

.buttons_switching_area {
  display: flex;
  position: absolute;
  top: 8px;
  right: 0px;
  align-items: center;
  background-color: #d7dbec;
  padding: 0.2rem;
  border-radius: 5px;
}
.buttons_switching_area button {
  width: 70px;
  border: none;
  font-weight: 500;
  background-color: #fff;
  outline: none;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
}
.buttons_switching_area button.active {
  background-color: #4dbfec;
  color: #fff;
}

/* CumulativeList */
.CumulativeList {
  margin-top: 3rem;
  border: 1px solid #707070;
  border-radius: 30px;
}
.CumulativeList .head,
.CumulativeList .body_area ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.CumulativeList .head > *,
.CumulativeList .body_area ul > * {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.CumulativeList .body_area ul > * {
  height: 70px;
}
.CumulativeList .head > * + *,
.CumulativeList .body_area ul > * + * {
  border-left: 1px solid #707070;
}
.CumulativeList .body_area ul {
  border-top: 1px solid #707070;
}

.CumulativeGraph {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

/* table_big_caption */
.table_big_caption {
  text-align: center;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 25px;
}

/* graph_points_area */
.graph_points_area {
  position: absolute;
  top: 0px;
  right: 0px;
}
.graph_points_area > div {
  color: #707070;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.graph_points_area > div p {
  width: 120px;
  padding-right: 20px;
  text-align: right;
}
.graph_points_area > div span {
  min-width: 35px;
  height: 35px;
  display: inline-block;

  border-radius: 50%;
  background-color: #000;
}
.graph_points_area > div:last-child span {
  border-radius: 0px;
}

/* phase_change */
.phase_change {
  grid-column: span 2;

  margin-bottom: 3rem;
}
.phase_change > * {
  padding-bottom: 1rem !important;
}

/* BehaviourNotes */
.BehaviourNotes {
  border: 1px solid #ddd;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.BehaviourNotes ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.BehaviourNotes ul > * {
  height: 100px;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.BehaviourNotes > ul > li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.BehaviourNotes ul > * + * {
  border-left: 1px solid #ddd;
}
.BehaviourNotes .body_area > * {
  border-top: 1px solid #ddd;
}
.BehaviourNotes textarea {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  height: 100%;
  resize: none;
}
/* graph_wrapper */
.graph_wrapper {
  display: flex;
  align-items: center;
}
.graph_wrapper img {
  cursor: pointer;
}
.graph_wrapper > *:nth-child(1) {
  margin-right: 1rem;
}
.graph_wrapper > .CumulativeGraph {
  flex: 1;
}
.graph_wrapper > *:nth-child(3) {
  margin-left: 1rem;
}
@media screen and (max-width: 1300px) {
  .percentage_bottom_area {
    grid-gap: 3rem;
  }

  .percentage_bottom_area > * {
    padding-bottom: 3rem !important;
    grid-column: span 2;
  }
  .phase_change {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .ProbResultContainer,
  .MaintainResultContainer {
    overflow: auto;
  }
  .ProbResultWrapper,
  .MaintainResultWrapper {
    min-width: 1000px;
  }
  .graph_wrapper img {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .buttons_switching_area {
    position: static;
    display: flex;
    background-color: transparent;
    justify-content: center;
    margin-top: 2rem;
  }
  .BehaviourNotesWrapper {
    overflow: auto;
  }
  .BehaviourNotes {
    min-width: 800px;
  }
}
@media screen and (max-width: 700px) {
  .CumulativeListWrapper {
    overflow: auto;
  }
  .CumulativeList {
    min-width: 700px;
  }
  .graph_points_area {
    top: 50px;
  }
  .graph_points_area > div span {
    min-width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 500px) {
  .PercentageArea > h1 {
    font-size: 27px;
  }
  .table_big_caption {
    font-size: 20px;
  }
}
@media screen and (max-width: 340px) {
  .graph_points_area {
    top: 70px;
  }
}
