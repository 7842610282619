.file-upload-wrapper {
  background-color: #f2f2f2;
  padding: 7rem 0rem;
}

.FileUploadHome .profile-information {
  padding-top: 0px !important;
}
.FileUploadHome .top-area {
  border-bottom: 1px solid #f2f2f2;
  padding: 1.5rem 0rem;
}
.FileUploadHome .top-area-content {
  width: 85%;
  margin: 0rem auto;
  text-align: center;
  position: relative;
}

.FileUploadHome .top-area-content h1 {
  font-size: 22px;
}

.FileUploadHome .top-area-content a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #7b8c9c;
}

.forms-wrapper {
  background: #fff;
  width: 85%;
  margin: 0rem auto;
  padding: 1rem 0rem;
  border-radius: 15px;
}
.form-top-nav {
  display: flex;
  align-items: center;
}
.form-top-nav > * {
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  background: #f4f6f9;
  padding: 2rem 4rem;
  cursor: pointer;
  /* border-radius:20px; */
}
.form-top-nav > *.active {
  color: #4dbfec;
  background: #fff;
}

.consent-form-content {
  width: 80%;
  margin: 5rem auto;
}
.consent-form-content h1,
.Assessment-form-content form .right-side h1 {
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
}
.Assessment-form-content form .right-side h1 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.consent-form-content form {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consent-form-content form input[type="file"],
#Assessment-file-input {
  display: none;
}
.consent-form-content form label,
.Assessment-form-content form .right-side label {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #f4f6f9;
  font-size: 18px;
  cursor: pointer;
}

.consent-form-content form #consent-submit-input,
.Assessment-form-content form #Assessment-submit-input {
  width: 400px;
  height: 50px;
  margin-top: 2rem;

  border: none;
  outline: none;
  border-radius: 99px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
}
.consent-form-content form #consent-submit-input:hover,
.Assessment-form-content form #Assessment-submit-input:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.Assessment-form-content form #Assessment-submit-input {
  width: 70%;
}

.drop-down-wrapper label {
  font-size: 18px;
}
.drop-down-wrapper .drop-down-pure {
  margin-top: 10px;
}
.drop-down-wrapper .drop-down-pure .drop-down-head {
  padding: 0.8rem 1rem;
  border: 1px solid #c8d2dc;
  cursor: pointer;
  border-radius: 10px;
  color: #525252;
  background: #fff;
}
.drop-down-wrapper .drop-down-pure .drop-down-result {
  display: none;
}
.drop-down-wrapper .drop-down-pure .drop-down-result.active {
  display: block !important;
}
.drop-down-wrapper .drop-down-pure .drop-down-result {
  padding: 1.5rem 1.2rem;
  background: #fff;
  border: 1px solid #c8d2dc;
  border-radius: 10px;
}
.drop-down-wrapper .drop-down-pure .drop-down-result input {
  display: none;
}
.drop-down-wrapper .drop-down-pure .drop-down-result label {
  font-size: 15px !important;
  cursor: pointer;
  display: block;
  width: 100%;
}
.drop-down-wrapper .drop-down-pure .drop-down-result > * + * {
  margin-top: 1.5rem;
}
.Assessment-form-content form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 3rem 2rem;
}
.Assessment-form-content form .right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.6rem;
  }
  .Assessment-form-content form {
    grid-template-columns: 1fr;
  }
  .form-top-nav > * {
    padding: 2rem 2rem;
  }
  .consent-form-content form #consent-submit-input,
  .Assessment-form-content form #Assessment-submit-input {
    width: 100%;
  }
  .consent-form-content {
    width: 100%;
    padding: 0rem 2rem;
  }
}
@media screen and (max-width: 550px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.4rem !important;
  }
  .forms-wrapper {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .form-top-nav {
    align-items: flex-start;
    text-align: center;
  }
  .form-top-nav > * {
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  .form-top-nav > *,
  .drop-down-wrapper label,
  .consent-form-content form label,
  .Assessment-form-content form .right-side label {
    font-size: 16px;
  }
  .drop-down-wrapper .drop-down-pure .drop-down-head {
    padding: 0.6rem 1rem;
  }
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.2rem !important;
  }
  .consent-form-content form #consent-submit-input,
  .Assessment-form-content form #Assessment-submit-input {
    height: 40px;
  }
}
