@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Atif Asim  butt */

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  scroll-behavior: smooth;
}
h4 {
  text-align: center;
  padding-bottom: 15px;
}
body,
button,
input {
  font-size: 80% !important;
}
body,
textarea,
button,
input,
a {
  font-family: "Rubik", sans-serif;
}
.fa-plus-square,
.trash-image {
  cursor: pointer !important;
}
label {
  cursor: pointer;
}

button {
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
}
button:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
iframe {
  display: none !important;
}
.show-notifications.hide {
  display: none;
}

nav,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}
.required_Ast {
  color: #ee0000;
  font-size: 18px;
  margin-top: 7px;
  margin-right: 5px;
}
.w-90 {
  width: 90%;
  margin: 0rem auto;
}
.Header {
  border-bottom: 1px solid #ccced0;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 4;
  background-color: #fff;
}
.noSticky {
  /* position: static; */
}
.header-content {
  padding: 0.5rem 0rem;
}
.header-content,
.header-content nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-content > img {
  width: 150px;
}
.main-header-nav > * + * {
  margin-left: 4rem;
}
.main-header-nav a {
  color: #515454;
  display: flex;
  align-items: center;
}
.main-header-nav a i {
  margin-right: 7px;
}
.main-header-nav a.active {
  color: #4dbfec !important;
}
.sign-out-button {
  background-color: #bec4c6;
  color: #fff;
  border: none;
  outline: none;
  width: 150px;
  height: 45px;
  border-radius: 99px;
  cursor: pointer;
  font-size: 18px;
}

@media screen and (max-width: 1100px) {
  .header-content nav {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1.5rem 2rem;
    background-color: #fff;
    width: 100%;
    z-index: 22;
  }
}
@media screen and (max-width: 540px) {
  .header-content > img {
    width: 120px;
  }
  .sign-out-button {
    height: 40px;
    font-size: 16px;
    width: 140px;
  }
  .header-content nav {
    padding: 1.5rem 2rem !important;
  }
}
@media screen and (max-width: 370px) {
  .sign-out-button {
    width: 110px;
    font-size: 15px;
  }
  .header-content > img {
    width: 100px;
  }
}

/* src/Assets/style/css/signup.css */
.SignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.SignUp img {
  width: 150px;
  margin-bottom: 20px;
}

.form {
  width: 100%;
  max-width: 400px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-wrapper i {
  margin-right: 10px;
}

.input-wrapper input {
  flex: 1 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sign-up-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.or-divider {
  margin: 20px 0;
  text-align: center;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}

.Login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Login > img {
  width: 230px;
}
.Login form {
  margin-top: 4rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login form > * + * {
  margin-top: 1.8rem;
}
.Login form .input-wrapper {
  background-color: #f6f8fa;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  border-radius: 15px;
  padding: 0rem 1rem;
}
.Login form .input-wrapper i {
  color: #c2cad2;
}
.Login form .input-wrapper input {
  flex: 1 1;
  height: 80%;
  width: 100%;
  border: none;
  outline: none;
  margin-left: 20px;
  color: #525252;
  font-weight: 400;
  font-size: 15px;
  background-color: transparent;
}
.Login form input[type="submit"] {
  background-color: #4dbfec;
  border: none;
  width: 150px;
  height: 43px;
  outline: none;
  font-size: 15px;
  border-radius: 99px;
  margin-top: 2.5rem;
  color: #fff;
  cursor: pointer;
}

.login-button {
  background-color: #4dbfec;
  border: none;
  width: 150px;
  height: 43px;
  outline: none;
  font-size: 15px;
  border-radius: 99px;
  margin-top: 2.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-divider {
  color: #b3b3b3;
}

.sign-up-link {
  color: #0593e6;
}

@media screen and (max-width: 500px) {
  .Login form {
    width: 90%;
    margin: 0rem auto;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 450px) {
  .Login > img {
    width: 60%;
  }
}

.header-content form {
  display: flex;
  align-items: center;
}
.header-content form input {
  height: 45px;
  width: 200px;
  margin-right: 10px;
  padding: 0rem 1rem;
  border-radius: 99px;
  border: 2px solid #ddd;
  outline: none;
}
.profile-information-content,
.profile-information-content .profile-about {
  display: flex;
  align-items: center;
  position: relative;
}
.add-new-client-button {
  width: 250px;
  height: 50px;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  border: none;
  outline: none;
  border-radius: 99px;
  position: absolute;
  bottom: -40px;
  left: 0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s ease all;
}
.add-new-client-button:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.profile-information {
  padding: 1rem 0rem;
  background-color: #fff;
  z-index: 5;
  position: static;
  top: 70px;
}
.profile-information-content .profile-about {
  flex: 1 1;
  margin-left: 3rem;
  align-items: flex-start;
}
.profile-information-content .profile-about > * {
  flex: 1 1;
}
.profile-information-content .profile-about > div {
  max-width: 370px;
}
.profile-information-content .profile-about div small {
  color: #4dbfec;
  margin-bottom: 1rem;
  display: inline-block;
}
.profile-information-content .profile-about > div:first-child {
  padding-right: 2rem;
  border-right: 1px solid #e3e8ed;
}
.profile-information-content .profile-about > div:last-child {
  padding-left: 2rem;
}
.profile-information-content .profile-about div h1 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #0e0e0e;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.profile-information-content .profile-about div h1 span {
  font-size: 13px;
  font-weight: 300;
  color: #b3b3b3;
  margin-top: 4px;
}
.profile-information-content .profile-about div p.email {
  color: #b3b3b3;
}
.profile-information-content .profile-ea-logo-white {
  width: 200px;
}
.profile_image_wrapper,
.profile_ea_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile_image_wrapper i,
.profile_ea_wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
  transition: 0.3s ease all;
  pointer-events: none;
  opacity: 0;
}
.profile-information-content .profile-image {
  width: 170px;
  border-radius: 20px;
  transition: 0.3s ease all;
}
.profile_ea_wrapper img {
  cursor: pointer;
}
.profile-information-content .profile-image:hover,
.profile_ea_wrapper img:hover {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}
.profile-information-content .profile-image:hover + i,
.profile_ea_wrapper img:hover + i {
  opacity: 1;
}
.profile-information-content .login-information {
  display: flex;
  align-items: center;
}
.profile-information-content .login-information > * {
  flex: 1 1;
  display: flex;
  align-items: center;
}
.profile-information-content .login-information > * i {
  margin-right: 10px;
  color: #a6a6a6;
}
.clients-working-section {
  background-color: #f2f2f2;
  padding: 4rem 0rem;
}
.clients-working-section .clients-box,
.edit-client-box {
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  margin-bottom: 3rem;
}
.clients-working-section .clients-box-top,
.clients-working-section .edit-client-box .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clients-working-section .clients-box-top button,
.edit-client-box .close-button {
  color: #4dbfec;
  border: 1px solid #4dbfec;
  border-radius: 99px;
  width: 140px;
  height: 45px;
  font-size: 17px;
  cursor: pointer;
  background-color: transparent;
}
.edit-client-box .close-button {
  background-color: #4dbfec;
  color: #fff;
}
.clients-working-section .clients-box-top p,
.clients-working-section .edit-client-box .top p {
  font-weight: 400;
  font-size: 17px;
}
.clients-working-section .clients-box-top p i {
  color: #4dbfec;
  margin-right: 10px;
}
.clients-working-section .edit-client-box .top p i {
  margin-right: 10px;
}
.clients-working-section .edit-client-box .top {
  color: #bdbbbb;
}
.clients-working-section .clients-box-top p span,
.clients-working-section .edit-client-box .top p span {
  margin-left: 2rem;
  font-size: 16px;
  color: #bdbbbb;
}
.client-information-edit {
  /* display: none; */
}
.client-information-edit {
  display: block;
}
.clients-working-section .edit-options,
.client-information-edit .edit-boxes {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-auto-rows: 200px;
  margin-top: 1rem;
  grid-gap: 1rem;
}
.client-information-edit .edit-boxes > *,
.clients-working-section .edit-options > * {
  grid-column: span 2;
}
.clients-working-section .edit-options .edit-option,
.client-information-edit .edit-boxes .edit-option {
  border: 1px solid #cfd8e0;
  border-radius: 10px;
  border-top: 6px solid #4dbfec;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.client-information-edit .edit-boxes .edit-option > *,
.clients-working-section .edit-options .edit-option > * {
  pointer-events: none;
}
.clients-working-section .edit-options .edit-option,
.edit-boxes .edit-option {
  position: relative;
}
.clients-working-section .edit-options .edit-option i,
.edit-boxes .edit-option i {
  position: absolute;
  bottom: 24px;
  font-size: 1.5rem;
}
.clients-working-section .edit-options .edit-option .badge-notify {
  width: 40px;
  height: 30px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 5px;
}
.clients-working-section .edit-options .edit-option p,
.client-information-edit .edit-boxes .edit-option p {
  flex: 1 1;
  display: flex;
  align-items: center;
}
.clients-working-section .edit-options .edit-option:first-child {
  border-top-color: #6db8e3;
}
.clients-working-section .edit-options .edit-option:first-child i,
.edit-boxes .edit-option:first-child i {
  color: #6db8e3;
}
.clients-working-section .edit-options .edit-option:first-child .badge-notify {
  background-color: #6db8e3;
}

.clients-working-section .edit-options .edit-option:nth-child(2) {
  border-top-color: #afd458;
}

.clients-working-section .edit-options .edit-option:nth-child(2) i {
  color: #afd458;
}

.clients-working-section .edit-options .edit-option:nth-child(2) .badge-notify {
  background-color: #afd458;
}
.clients-working-section .edit-options .edit-option:nth-child(3) {
  border-top-color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(3) i {
  color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(3) .badge-notify {
  background-color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(4) {
  border-top-color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(4) i,
.edit-boxes .edit-option:nth-child(2) i {
  color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(4) .badge-notify {
  background-color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(5) {
  border-top-color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(5) i,
.edit-boxes .edit-option:nth-child(3) i {
  color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(5) .badge-notify {
  background-color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(6) {
  border-top-color: #f0595e;
}

.clients-working-section .edit-options .edit-option:nth-child(6) i,
.edit-boxes .edit-option:nth-child(4) i {
  color: #f0595e;
}
.edit-boxes .edit-option:nth-child(5) i {
  color: #aaafb2;
}

.clients-working-section .edit-options .edit-option:nth-child(6) .badge-notify {
  background-color: #f0595e;
}

.clients-working-section .edit-options .edit-option:nth-child(7) {
  border-top-color: #214fe7;
}
.clients-working-section .edit-options .edit-option:nth-child(7) i {
  color: #214fe7;
}
.clients-working-section .edit-options .edit-option:nth-child(7) .badge-notify {
  background-color: #214fe7;
}

.clients-working-section .edit-options .edit-option:nth-child(8) {
  border-top-color: #e984bf;
}
.clients-working-section .edit-options .edit-option:nth-child(8) i {
  color: #e984bf;
}
.clients-working-section .edit-options .edit-option:nth-child(8) .badge-notify {
  background-color: #e984bf;
}

.clients-working-section .edit-options .client_info {
  box-shadow: 5px 5px 10px #6db8e3;
}

.clients-working-section .edit-options .ConsentForm {
  box-shadow: 5px 5px 10px #afd458;
}
.clients-working-section .edit-options .AssessmentGraphs {
  box-shadow: 5px 5px 10px #81d4b9;
}
.clients-working-section .edit-options .IndividualPlan {
  box-shadow: 5px 5px 10px #e79121;
}

.clients-working-section .edit-options .CurrentPrograms {
  box-shadow: 5px 5px 10px #9f7dd9;
}

.clients-working-section .edit-options .BehaviouralManagement {
  box-shadow: 5px 5px 10px #f0595e;
}

.clients-working-section .edit-options .edit-option::after {
  content: "";
  width: 100%;
  height: 3px;
  top: 110%;
  left: 0;
  position: absolute;
}
.clients-working-section .edit-options .edit-option:nth-child(1).active::after {
  background: #6db8e3;
}
.clients-working-section .edit-options .edit-option:nth-child(2).active::after {
  background: #afd458;
}
.clients-working-section .edit-options .edit-option:nth-child(3).active::after {
  background: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(4).active::after {
  background: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(5).active::after {
  background: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(6).active::after {
  background: #f0595e;
}
.clients-working-section .edit-options .edit-option:nth-child(7).active::after {
  background: #214fe7;
}

.clients-working-section .edit-client-box .bottom {
  margin-top: 1rem;
}
.clients-working-section .edit-client-box .bottom p {
  font-size: 17px;
}
.client-information-edit .edit-boxes .edit-option {
  border: 1px dashed #aaafb2;
  color: #000;
}

/* pop ups */

.show-notifications {
  background: #f4f6f9;
  margin-top: 2rem;
  padding: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  border-radius: 10px;

  grid-gap: 10px;
  overflow: auto;
  max-height: 330px;
  padding-top: 5rem;
  position: relative;
}
.show-notifications form {
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 20px;
}
.show-notifications .input_wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  width: 300px;
  padding: 0rem 15px;
  margin-right: 10px;
}
.show-notifications .input_wrapper input {
  flex: 1 1;
  margin-left: 5px;
  border: none;
  font-size: 16px;
  outline: none;
}
.show-notifications .select_wrapper {
  background-color: #fff;
  border-radius: 10px;
  border-radius: 10px;
  padding: 0rem 10px;
  height: 50px;
}
.show-notifications .select_wrapper select {
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}
.show-notifications::-webkit-scrollbar {
  display: none;
}
.show-notifications .notification-box {
  border: 1px solid #dde2e7;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: #000;
  align-items: flex-start;
  border-radius: 10px;
  min-height: 200px;
  position: relative;
}
.show-notifications .notification-box .top-area {
}
.show-notifications .notification-box .not_locked {
  justify-content: flex-end !important;
}
.show-notifications .add-box,
.show-notifications .edit-option.upload-files-consent,
.show-notifications .edit-option.upload-files-assessment {
  border: 1px dashed #4dbfec;
  background: #f6fcff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-notifications .add-box.active {
  display: flex !important;
}
.show-notifications .add-box i {
  color: #4dbfec;
  font-size: 60px;
}
.show-notifications .edit-option.upload-files-consent,
.show-notifications .edit-option.upload-files-assessment {
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem 2rem;
}
.show-notifications .edit-option.upload-files-consent p,
.show-notifications .edit-option.upload-files-assessment p {
  text-align: center;
  color: #000000;
}
.show-notifications .edit-option.upload-files-consent i,
.show-notifications .edit-option.upload-files-assessment i {
  color: #aaafb2;
  font-size: 40px;
}
.show-notifications .notification-box {
  min-height: 220px;
}
.show-notifications .notification-box a {
  color: #000 !important;
}
.show-notifications .notification-box .top-area {
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
}

.show-notifications .notification-box .top-area .action-image {
  width: 23px;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAABHNCSVQICAgIfAhkiAAAAgdJREFUWEftmE1OwkAUgN8rsDCBwg3QE9ilAokVXJqoJxBugCcQT4CeQD2BuJemJIAu8QZwA0CMC2mfMwOltRFobWHVrmjn/XzzfuYHBNejkpKJjZOXgHDOhlQ+TARDROgBQcOQJ4869oZuvaDv6DRQHOfKQFKdOc0sMzyDMipN+bUR1LlTfwFSHBVqDODaHqRnIuwt3pHOEXDfeicwK5rcfQgLRoCUxocsDbEnkQagd1Oisp7s2hBzbycfBZVMagBimn9i4McvqbYeBowAKY7yfUTMEtHAlD+VVTWgTnJKzECdwzD5vpbu7IUCwusCQbr3M0OnDoBxEUa9YGmcZ0WHZzwlmtxRvM6uNMoPRVTQvNNS3apXvWVyuEiLT4P2BKClyW3R5kEeFpECiSIluNHS7ZpXY1aXhVUngUE4eFNu/1qPvE7GKReBuKPmiAhdsTXhdlVY1a+DXZwmsrOaMssSYJn9nBDi6X/SYelQ/HuAfIGKk5TxskJaLRvE6V+6Yv/yY3TRYQAtP3qrZBnAkVhM/Ri0QMLoEsuvZTMCiSKyrBY3WiPizGJKdSMxreg7b/1VDbFREHtDXL+RRiDuNEUR2XJE8lV2K6h7uftsNDV81vzIsK51udzGQbxuphHIVovVa1oC1wgzoPtxtkZW9X9Cm18zQ4QQpvjl39cJjSvxvybCBuEH9x+ftnS+jrQIqAAAAABJRU5ErkJggg==);
  background-position: center;
  background-size: contain;
  height: 31px;
  cursor: pointer;
  background-repeat: no-repeat;
}
.show-notifications .notification-box .top-area .action-image.active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAgCAYAAAAIXrg4AAAABHNCSVQICAgIfAhkiAAAAd9JREFUSEvtVsttwkAQnTEXcsLugA5CKojTAbkZiY/TAVQQqACoIBYg2TeggjgdOBXEqcDkBBc82fUPx7ENGCIhhT1Ylnb2vZnZmbeDsGc5SlMWADsEKANCNTC3EMh0qTSWDM3Og8CsTaeuiljezgEZcM5Cgn7FmAyyTFIJPPAb94MdEsODRPTFIrCAQETE2zggEmkVY/qURpJO0GiZCHjPDxDQJ/t2JX22CAG4A1B2u4jwHDkA1JP06ShJ8ovAaTTrCMI8Al+XatJCW6V55ygtlUXzEuytRH0i7SdQ2hrzrOMTuI9xz9NJYvbkPkjGzPyRvuQhJ5Ye5lFmEUTpikVBBAPJmPTPTNCUEYVXL+IrQfolX0KKHEWtCuAGpQlqqDlcBvJkwt+jKiGq/i/xBjVZmdvbtbDk/YPxxtoPdoQFgU0b4Q5ZN1pJbTkCJteUly2uGm3yo6N3rjkngyPWWJqGAeZyRwD0xsRKPpWAvx9R4zHMf0TASxlxO2TqZ1f0aS8rlYVT5CjtfvjAUIos79S14B1cCfjjntsHf5+iYBjwOn5TkrMHgYKXfGiHFy7TyydgHq6YpFqHeppph8jHzZqnpgBj9h7sBqeTwRMAfHDDYM4chdPcOUj8eRZGfFb9BqzcqaPww18oAAAAAElFTkSuQmCC);
}
.show-notifications .notification-box .top-area i {
  color: #c8d2dc;
}
.show-notifications .notification-box .presentation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  padding: 0rem 0.4rem;
  width: 100%;
}
.show-notifications .notification-box .presentation .buttons-wrapper {
  width: 100% !important;
  margin: 10px 0px;

  flex: 1 1;
}
.show-notifications .notification-box .presentation .buttons-wrapper a {
  display: block;
  width: 100%;
  font-size: 14px;
  background-color: #00c1f1;
  border-radius: 99px;
  text-align: center;
  color: #fff !important;
  padding: 0.6rem 0.5rem;
}
.show-notifications
  .notification-box
  .presentation
  .buttons-wrapper
  a:first-child {
  margin-bottom: 5px;
}
.show-notifications .notification-box h2 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.7rem;
}

.show-notifications .notification-box span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 105%;
  color: #a2a2a2;
}

.show-notifications .notification-box .edit-detail h6 {
  margin-bottom: 5px;
  color: #a5b0bb;
}
.show-notifications .notification-box .edit-detail p {
  word-break: break-all;
  font-size: 14px;
}
@media screen and (max-width: 1100px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes {
    grid-template-columns: repeat(5, 1fr);
  }
  .client-information-edit .edit-boxes > *,
  .clients-working-section .edit-options > * {
    grid-column: span 1;
  }
  .profile-information-content .profile-ea-logo-white {
    display: none;
  }
  .show-notifications {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 900px) {
  .profile-information {
    padding-bottom: 5rem;
  }
  .add-new-client-button {
    bottom: -100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .profile-information-content {
    flex-direction: column-reverse;
  }
  .profile-information-content .profile-about {
    margin-left: 0px;
    flex-direction: column;
    /* align-items: center; */
    align-items: flex-start;
  }
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    padding-right: 0px;
    border: none;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .profile-information-content .profile-about > div:first-child {
    margin-bottom: 1rem;
  }
  .profile-information-content .profile-about > div:last-child {
    padding-left: 0px;
  }
  .profile-information-content .profile-about div small {
    margin-bottom: 0.5rem;
  }
  .profile-information-content .login-information {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .profile-information-content .login-information > * + * {
    margin-top: 6px;
  }
}
@media screen and (max-width: 850px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 730px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(3, 1fr);
  }
  .main-header-nav a {
    flex-direction: column;
    align-items: center;
  }
  .main-header-nav a i {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 630px) {
  .header-content form input {
    display: none;
  }
  .main-header-nav a p {
    display: none;
  }
  .header-content nav {
    padding: 1.5rem 4rem;
  }
  .main-header-nav a i {
    margin: 0px !important;
  }
}
@media screen and (max-width: 540px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(2, 1fr);
  }
  .show-notifications {
    /* padding: 1rem; */

    padding-top: 10rem;
  }

  .show-notifications form {
    flex-direction: column;
    width: 90%;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .show-notifications .select_wrapper {
    width: 100%;
    margin-top: 10px;
  }
  .show-notifications .input_wrapper {
    margin-right: 0px;
    width: 100%;
  }
  .show-notifications .select_wrapper select {
    width: 100%;
  }
  .profile-information-content .profile-image {
    width: 130px;
  }
  .add-new-client-button {
    width: 190px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .clients-working-section .clients-box-top,
  .clients-working-section .edit-client-box .top {
    flex-direction: column;
  }
  .clients-working-section .clients-box-top > * + *,
  .clients-working-section .edit-client-box .top > * + * {
    margin-top: 1rem;
  }
  .clients-working-section .clients-box-top p,
  .clients-working-section .edit-client-box .top p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .clients-working-section .clients-box-top p span,
  .clients-working-section .edit-client-box .top p span {
    margin-left: 0px;
  }
}
@media screen and (max-width: 370px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PopUpConfirm {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopUpConfirm > * {
  background-color: #fff;
  width: 550px;
  height: 400px;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.PopUpConfirm h1 {
  font-size: 22px;
  text-align: center;
}
.PopUpConfirm .buttons_wrapper {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.PopUpConfirm .buttons_wrapper > * + * {
  margin-left: 10px;
}
.PopUpConfirm .buttons_wrapper button {
  width: 100px;
  height: 50px;
  border-radius: 10px;
}

.file-upload-wrapper {
  background-color: #f2f2f2;
  padding: 7rem 0rem;
}

.FileUploadHome .profile-information {
  padding-top: 0px !important;
}
.FileUploadHome .top-area {
  border-bottom: 1px solid #f2f2f2;
  padding: 1.5rem 0rem;
}
.FileUploadHome .top-area-content {
  width: 85%;
  margin: 0rem auto;
  text-align: center;
  position: relative;
}

.FileUploadHome .top-area-content h1 {
  font-size: 22px;
}

.FileUploadHome .top-area-content a {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #7b8c9c;
}

.forms-wrapper {
  background: #fff;
  width: 85%;
  margin: 0rem auto;
  padding: 1rem 0rem;
  border-radius: 15px;
}
.form-top-nav {
  display: flex;
  align-items: center;
}
.form-top-nav > * {
  flex: 1 1;
  font-size: 18px;
  font-weight: 500;
  background: #f4f6f9;
  padding: 2rem 4rem;
  cursor: pointer;
  /* border-radius:20px; */
}
.form-top-nav > *.active {
  color: #4dbfec;
  background: #fff;
}

.consent-form-content {
  width: 80%;
  margin: 5rem auto;
}
.consent-form-content h1,
.Assessment-form-content form .right-side h1 {
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
}
.Assessment-form-content form .right-side h1 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.consent-form-content form {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consent-form-content form input[type="file"],
#Assessment-file-input {
  display: none;
}
.consent-form-content form label,
.Assessment-form-content form .right-side label {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #f4f6f9;
  font-size: 18px;
  cursor: pointer;
}

.consent-form-content form #consent-submit-input,
.Assessment-form-content form #Assessment-submit-input {
  width: 400px;
  height: 50px;
  margin-top: 2rem;

  border: none;
  outline: none;
  border-radius: 99px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
}
.consent-form-content form #consent-submit-input:hover,
.Assessment-form-content form #Assessment-submit-input:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.Assessment-form-content form #Assessment-submit-input {
  width: 70%;
}

.drop-down-wrapper label {
  font-size: 18px;
}
.drop-down-wrapper .drop-down-pure {
  margin-top: 10px;
}
.drop-down-wrapper .drop-down-pure .drop-down-head {
  padding: 0.8rem 1rem;
  border: 1px solid #c8d2dc;
  cursor: pointer;
  border-radius: 10px;
  color: #525252;
  background: #fff;
}
.drop-down-wrapper .drop-down-pure .drop-down-result {
  display: none;
}
.drop-down-wrapper .drop-down-pure .drop-down-result.active {
  display: block !important;
}
.drop-down-wrapper .drop-down-pure .drop-down-result {
  padding: 1.5rem 1.2rem;
  background: #fff;
  border: 1px solid #c8d2dc;
  border-radius: 10px;
}
.drop-down-wrapper .drop-down-pure .drop-down-result input {
  display: none;
}
.drop-down-wrapper .drop-down-pure .drop-down-result label {
  font-size: 15px !important;
  cursor: pointer;
  display: block;
  width: 100%;
}
.drop-down-wrapper .drop-down-pure .drop-down-result > * + * {
  margin-top: 1.5rem;
}
.Assessment-form-content form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 3rem 2rem;
}
.Assessment-form-content form .right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.6rem;
  }
  .Assessment-form-content form {
    grid-template-columns: 1fr;
  }
  .form-top-nav > * {
    padding: 2rem 2rem;
  }
  .consent-form-content form #consent-submit-input,
  .Assessment-form-content form #Assessment-submit-input {
    width: 100%;
  }
  .consent-form-content {
    width: 100%;
    padding: 0rem 2rem;
  }
}
@media screen and (max-width: 550px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.4rem !important;
  }
  .forms-wrapper {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .form-top-nav {
    align-items: flex-start;
    text-align: center;
  }
  .form-top-nav > * {
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  .form-top-nav > *,
  .drop-down-wrapper label,
  .consent-form-content form label,
  .Assessment-form-content form .right-side label {
    font-size: 16px;
  }
  .drop-down-wrapper .drop-down-pure .drop-down-head {
    padding: 0.6rem 1rem;
  }
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.2rem !important;
  }
  .consent-form-content form #consent-submit-input,
  .Assessment-form-content form #Assessment-submit-input {
    height: 40px;
  }
}

.InsertPdf > form {
  width: 90%;
  margin: 4rem auto;
}
.InsertPdf > form input {
  display: none;
}

.InsertPdf > form label {
  width: 100%;
  height: 100vh;
  background-color: #cbe9e4;
  border: 1px solid #707070;
  cursor: pointer;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  color: #707070;
}
.InsertPdf > form label p {
  text-align: center;
}
.InsertPdf > form label p span {
  display: block;

  margin-top: 5px;
}

.assign-instructor-wrapper {
  padding: 5rem 0rem;
  background-color: #f2f2f2;
}
.assign-instructor-content {
  width: 85%;
  margin: 0rem auto;
}
.assign-instructor {
  min-height: 400px;
  padding: 2rem 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-gap: 2rem;
  margin-bottom: 4rem;
}
.assign-instructor .left-side,
.assign-instructor .right-side,
.assign-instructor .middle-side {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.assign-instructor .left-side .intructor-info,
.assign-instructor .right-side .image-wrapper {
  flex: 1 1;
}
.assign-instructor .left-side .name {
  font-size: 22px;
  font-weight: 500;
}
.assign-instructor .left-side .email {
  font-size: 16px;
  color: #a6a6a6;
}
.assign-instructor .left-side button {
  width: 170px;
  height: 40px;
  background-color: #4dbfec;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}

.assign-instructor .right-side {
  align-items: center;
}
.assign-instructor .right-side > p {
  color: #b0b0b0;
  text-align: center;
  font-size: 16px;
}
.assign-instructor .right-side .image-wrapper {
  width: 70%;
}
.assign-instructor .right-side .image-wrapper img {
  width: 100%;
}
.assign-instructor .middle-side > h1 {
  font-size: 17px;
  color: #4dbfec;
  font-weight: 500;
}
.assign-instructor .middle-side ul {
  width: 100%;
  margin-top: 20px;
  height: 280px;
  overflow: auto;
}

.assign-instructor .middle-side ul::-webkit-scrollbar,
.edit-details > div ul::-webkit-scrollbar {
  width: 7px;
}
.assign-instructor .middle-side ul::-webkit-scrollbar-thumb,
.edit-details > div ul::-webkit-scrollbab-thumb {
  width: 100%;
  background-color: #6db8e3;
  border-radius: 99px;
}
.assign-instructor .middle-side ul li,
.edit-details > div ul li {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
}
.edit-details {
  position: relative;
}
.edit-details > * {
  position: relative;
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}
.arrow-box {
  position: absolute;
  left: 50%;
  top: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
  z-index: 222;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 20px;
}
.arrow-box::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1px;
  background-color: #ddd;
}
.arrow-box > * {
  padding: 1rem;
  cursor: pointer;
  pointer-events: none;
}
.arrow-box > * i {
  font-size: 1.5rem;
  color: #a6a6a6;
}

.arrow-box > *.active i {
  color: #4dbfec;
}
.arrow-box > *.active {
  cursor: pointer;
  pointer-events: unset;
}
.assign-instructor .middle-side ul li {
  padding-right: 3rem;
  position: relative;
}
.Assigned-wrapper .mask {
  display: none;
}

.Assigned-wrapper ul .fa-times-circle {
  color: #ee0000;
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: none;
}

.edit-details > div ul li input:checked + label + .fa-times-circle {
  display: block !important;
}
.clients-id-wrapper .information li {
  width: 100%;
  display: block;

  padding: 0px !important;
}
.clients-id-wrapper .information li div label {
  padding: 1.5rem;
  cursor: pointer !important;
}
.clients-id-wrapper .information li div label:hover {
  background-color: #f6f8fa !important;
}
.clients-id-wrapper .information li div label .name {
  font-size: 1.1rem;
  flex: 1 1;
}
.assign-instructor .middle-side ul li.active {
  background-color: #f6f8fa;
}
.assign-instructor .middle-side ul li.active .fa-times-circle,
.Assigned-wrapper ul li.active .fa-times-circle {
  color: #c62b2b;
  cursor: pointer;
}
/* time-circle */
.assign-instructor .middle-side ul li p.name i {
  color: #a6a6a6;
  margin-right: 10px;
}
.assign-instructor .middle-side ul li p.name,
.edit-details > div ul li p.name {
  font-size: 15px;
  font-weight: 500;
}
.assign-instructor .middle-side ul li p:nth-child(2),
.edit-details > div ul li p:nth-child(2) {
  font-size: 14px;
}
.assign-instructor .middle-side ul li.time-circle,
.Assigned-wrapper ul li.active {
  background-color: #f6f8fa;
  border-radius: 10px;
  border-color: #ebeff3;
}

/* edit-details */
.edit-details {
  background-color: #f4f6f9;
  padding: 2rem;
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.edit-details > div h1 {
  font-size: 16px;
  font-weight: 500;
  padding-left: 2rem;
}

.edit-details > div ul {
  height: 250px;
  background-color: #fff;
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  border: 1px solid #c8d2dc;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: auto;
}
.edit-details > div ul li {
  padding-left: 2rem;
  padding-right: 2rem;
}
.edit-details > div:last-child ul {
  border-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 0px;
}
.edit-details > div ul .custom-checkbox {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #fff;
  border: 1px solid #c8d2dc;
}
.edit-details > div ul label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.Assigned-wrapper ul li {
  padding-right: 3rem !important;
  position: relative;
}

.edit-details.active {
  display: grid;
}
.edit-details > div ul label p.name {
  flex: 1 1;
}
.edit-details > div ul li input {
  display: none;
}
.edit-details > div ul li input:checked + label .custom-checkbox {
  background-color: #4dbfec;
}
.edit-details .buttons-wrapper {
  margin-top: 2rem;
  display: flex;
  grid-column: span 2;
  align-items: center;
  justify-content: center;
}
.edit-details .buttons-wrapper button:first-child {
  background-color: #c8d2dc;
  margin-right: 10px;
}
.edit-details .buttons-wrapper button {
  width: 180px;
  height: 50px;
  background-color: #4dbfec;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}
/* .profile-information-content */
@media screen and (max-width: 750px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1100px) {
  .AssignInstructor .profile-information-content {
    padding: 3rem 0rem;
  }
  .assign-instructor {
    grid-template-columns: 1fr;
  }
  .assign-instructor > .left-side {
    order: 2;
  }
  .assign-instructor > .middle-side {
    order: 3;
  }
  .assign-instructor > .right-side {
    order: 1;
  }
  .assign-instructor .left-side button {
    margin-top: 1rem;
  }
  .assign-instructor .right-side {
    align-items: flex-start;
  }
  .assign-instructor .right-side .image-wrapper img {
    width: 150px;
  }

  .assign-instructor .right-side > p {
    margin-top: 10px;
  }
  .edit-details {
    grid-column: span 1;
    order: 4;
  }
}
@media screen and (max-width: 850px) {
  .edit-details {
    grid-template-columns: 1fr !important;
    grid-gap: 1rem;
  }
  .edit-details .buttons-wrapper {
    grid-column: span 1;
  }
  .edit-details > div h1 {
    padding-left: 0rem;
  }
}
@media screen and (max-width: 620px) {
  .buttons-wrapper > * {
    width: 100% !important;
  }
}
@media screen and (max-width: 470px) {
  .assign-instructor-content {
    width: 95%;
  }
  .edit-details > div ul li {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 370px) {
  .edit-details {
    padding: 2rem 1rem;
  }
  .edit-details > div ul li {
    padding-left: 1rem;
    padding-right: 1rem;
    padding: 0.7rem 1rem !important;
  }
  .edit-details > div ul label {
    flex-direction: column;
    align-items: flex-start;
  }
  .edit-details > div ul label > * + * {
    margin-top: 5px;
  }
}
@media screen and (max-width: 370px) {
  .edit-details .buttons-wrapper {
    flex-direction: column;
  }
  .edit-details .buttons-wrapper > *:first-child {
    margin-right: 0rem !important;
    margin-bottom: 10px;
  }
}

.AddClientInformation .profile-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem !important;
}
#checkbox-error-correction2:checked + label i {
  color: #4cbfed;
}
.bypassEnter {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  pointer-events: none;
}
.by_pass_wrapper:checked + label .bypassEnter {
  pointer-events: unset;
}
.AddClientInformation .profile-information {
  padding-top: 0px !important;
}
.AddClientInformation .top-area {
  border-bottom: 1px solid #f2f2f2;
  padding: 1.5rem 0rem;
}
.AddClientInformation .top-area-content {
  width: 85%;
  margin: 0rem auto;
  text-align: center;
  position: relative;
}
.AddClientInformation .top-area-content a {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #7b8c9c;
}
.AddClientInformation .top-area-content a i {
  font-size: 20px;
}
.AddClientInformation .top-area-content h1 {
  font-size: 22px;
}
@media screen and (min-width: 1400px) {
  .AddClientInformation .profile-about {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .AddClientInformation .profile-about > *:nth-child(2) {
    grid-column: span 2;
    width: 45%;
  }
}
.AddNewCurrentProgram .profile-information-content .profile-about,
.IndividualSupportPlan .profile-information-content .profile-about,
.BehaviourSupportPlan .profile-information-content .profile-about {
  grid-template-columns: 1fr 1fr 1fr;
}
.AddNewCurrentProgram
  .profile-information-content
  .profile-about
  > *:nth-child(1),
.IndividualSupportPlan
  .profile-information-content
  .profile-about
  > *:nth-child(1),
.BehaviourSupportPlan
  .profile-information-content
  .profile-about
  > *:nth-child(1) {
  grid-column: span 2;
  width: 100%;
}
.AddNewCurrentProgram .profile-information-content .profile-about label,
.IndividualSupportPlan .profile-information-content .profile-about label,
.BehaviourSupportPlan .profile-information-content .profile-about label {
  width: 100px;
}
.AddNewCurrentProgram .select-container,
.IndividualSupportPlan .select-container,
.BehaviourSupportPlan .select-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.AddNewCurrentProgram .select-container > *,
.IndividualSupportPlan .select-container > *,
.BehaviourSupportPlan .select-container > * {
  flex: 1 1;
  width: 100%;
}
.AddNewCurrentProgram .select-container > *:first-child,
.IndividualSupportPlan .select-container > *:first-child,
.BehaviourSupportPlan .select-container > *:first-child {
  margin-right: 10px;
}
.AddNewCurrentProgram .profile-information-content .profile-about,
.IndividualSupportPlan .profile-information-content .profile-about,
.BehaviourSupportPlan .profile-information-content .profile-about {
  padding-top: 3rem;
}
.AddClientInformation .profile-about .input-container {
  padding: 0px !important;
  border-right: none !important;
  max-width: 90%;
}
.AddClientInformation .profile-about .input-container.month label {
  font-weight: 400;
  font-size: 15px;
  color: #979797;
}
.AddClientInformation .profile-about label {
  width: 120px;
  color: #4dbfec;
  font-weight: 500;
  margin-right: 10px;
}
.AddClientInformation .profile-about .input-wrapper {
  display: flex;
  align-items: center;
  flex: 1 1;

  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0.2rem;
  max-width: 100% !important;
}

.AddClientInformation .profile-about .input-wrapper {
  color: #c2cad2;
}
.AddClientInformation .profile-about .input-wrapper input {
  width: 100%;
  border: none;
  font-family: "Rubik", sans-serif;
  flex: 1 1;
  outline: none;
  padding: 0rem 0.5rem;
}
.AddClientInformation .profile-about > .input-container {
  display: flex;
  align-items: flex-end;
}
.AddClientInformation .main-form-area {
  padding: 2rem 0rem;
  background-color: #f2f2f2;
}

.AddClientInformation .main-form-area-content {
  padding: 2rem;
  border-radius: 10px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 2rem;
}

.AddClientInformation .main-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.AddClientInformation .main-heading img {
  margin-right: 10px;
  width: 35px;
}

.AddClientInformation .main-heading p {
  color: #4dbfec;
}
.AddClientInformation .navigation-area ul {
  margin-top: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}
.pure-form-area > span {
  position: relative;
  top: -100px;
}
.AddClientInformation .navigation-area ul li {
  cursor: pointer;
  text-align: right;
}
.AddClientInformation .navigation-area ul li a {
  text-decoration: none;
  color: #000;
  padding: 0.8rem 0.4rem;
  padding-right: 15px;
  width: 100%;
  display: inline-block;
  /* margin-top: -40px; */
}
.AddClientInformation .navigation-area ul li.save-template-button button {
  background-color: #4dbfec;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  padding: 0rem 2rem;
  border-radius: 15px;
}
.AddClientInformation .navigation-area ul li a.active {
  background-color: #f6f8fa;
  border-right: 5px solid #4dbfec;
  border-radius: 7px;
  font-weight: 600;
}
.AddClientInformation .pure-form-area .input-collection {
  padding: 2rem 0rem;
  border-bottom: 1px solid #dbdbdb;
}

.AddClientInformation .pure-form-area .input-collection.d-flex {
  display: flex;
  align-items: center;
}
.AddClientInformation .pure-form-area .input-collection.d-flex > div > label {
  margin-bottom: 10px;
}
.AddClientInformation .pure-form-area .input-collection.d-flex .left-side {
  margin-right: 30px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper .select-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c8d2dc;
  height: 45px;
  border-radius: 10px;
  padding: 0rem 1rem;
  cursor: pointer;
}

.select-wrapper .select-heading > * {
  color: #525252;
}
.select-wrapper .select-body,
.special-select-body {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: none;
}
.special-select-body {
  display: block;
  position: static;
}
.select-wrapper .select-body.active {
  display: block;
}
.select-wrapper .select-body > li:first-child,
.special-select-body > li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.select-wrapper .select-body > li:last-child,
.special-select-body > li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.select-wrapper .select-body > li:hover,
.special-select-body > li:hover {
  background-color: #4dbfec;
}

.select-wrapper .select-body > li a,
.select-wrapper .select-body li label,
.special-select-body > li a,
.special-select-body li label {
  width: 100%;
  display: block;
  color: #000;
  padding: 1rem 1rem;
  border-radius: 10px;
  font-weight: 200;
  cursor: pointer;
}

.select-wrapper .select-body li label:hover,
.special-select-body li label:hover {
  color: #fff;
}
/* behaviour-form-content-area */
.AddClientInformation
  .pure-form-area
  .input-collection
  .select-wrapper
  .select-body
  li
  input,
.select-wrapper .select-body li input,
.special-select-body li input {
  display: none;
}
.important-variable {
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-top: 1px solid #e8e8e8;
}
.important-variable:hover {
  background-color: #fff !important;
}
.important-variable .count {
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.8fr;
  width: 50%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  height: 50px;
}
.important-variable .count > * {
  display: flex;
  align-items: center;
  justify-content: center;
}
.important-variable .count .arrow-left {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.important-variable .count .arrow-right {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.important-variable .count .arrow > * {
  pointer-events: none;
}
.important-variable .count .arrow-left,
.important-variable .count .arrow-right {
  cursor: pointer;
}
.select-wrapper .Applicable-Assessments-select-body li {
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
}
.select-wrapper .Applicable-Assessments-select-body li input {
  display: block !important;
  width: 15px;
  height: 15px;
}
.select-wrapper .select-body > li:hover {
  color: #fff;
}
.selecters-grid {
  display: grid;
  width: 100%;

  margin-top: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
}
.selecters-grid > * {
  width: 100% !important;
  grid-column: span 2;
}

.AddClientInformation .pure-form-area .input-collection .right-side input {
  height: 47px;
  background-color: #f6f8fa;
  width: 100%;
  border: none;
  padding: 0rem 1rem;
  outline: none;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
}
.AddClientInformation .pure-form-area .input-collection.d-flex > * {
  flex: 1 1;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper,
.behaviour-form-content-area .input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f6f8fa;
  height: 45px;
  padding: 0rem 1rem;
  border-radius: 10px;
}
.AddClientInformation .pure-form-area .input-collection.text-input-area h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
.AddClientInformation .pure-form-area .input-collection.text-input-area p {
  color: #576068;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper input {
  width: 100%;
  flex: 1 1;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper img {
  width: 20px;
}

.AddClientInformation .pure-form-area > *:first-child {
  padding-top: 0rem;
}

.AddClientInformation .pure-form-area label {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.AddClientInformation .pure-form-area label i {
  color: #6db8e3;
  font-size: 20px;
  margin-left: 10px;
}
.text-area-wrapper {
  position: relative;
}
.AddClientInformation .pure-form-area .input-wrapper > i {
  margin-left: 10px;
}

.AddClientInformation .pure-form-area .input-wrapper > input {
  margin-left: 10px;
}
.text-area-wrapper > i {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #c2cad2;
}

.pure-form-area i.fa-pen {
  color: #c2cad2;
}
.text-area-wrapper > textarea {
  text-indent: 30px;
}
.AddClientInformation .pure-form-area textarea {
  height: 165px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-top: 10px;
  background-color: #f6f8fa;
  border-radius: 15px;

  resize: none;
  border: none;
  font-family: "Rubik", sans-serif;
  outline: none;

  padding: 1rem;
}
.AddClientInformation .pure-form-area .checkbox input,
.BehaviourManagementTools .checkbox input,
.notification-table-area .checkbox input {
  display: none;
}
.AddClientInformation .pure-form-area .custom-checkbox,
.BehaviourManagementTools .custom-checkbox,
.notification-table-area .custom-checkbox {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
}
/* checkboxes */
.AddClientInformation .pure-form-area .checkboxes {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.AddClientInformation .pure-form-area .checkboxes > * {
  min-width: 100px;
  margin-top: 10px;
}

.AddClientInformation .pure-form-area .custom-checkbox i,
.BehaviourManagementTools .custom-checkbox i,
.notification-table-area .checkbox i {
  margin: 0;
  color: transparent;
  font-size: 14px;
}
.AddClientInformation .pure-form-area label p,
.BehaviourManagementTools label p {
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 20px;
}
.AddClientInformation
  .pure-form-area
  .checkboxes
  .checkbox
  input:checked
  + label
  .custom-checkbox
  i,
.BehaviourManagementTools
  .checkboxes
  .checkbox
  input:checked
  + label
  .custom-checkbox
  i,
#Error-Correction .checkbox input:checked + label .custom-checkbox i {
  color: #4dbfec !important;
}
.special-select-body > li > input:checked + label {
  background-color: #4dbfec;
  color: #fff;
}

.notification-table-area .checkbox input:checked + label i {
  color: #fff;
}
.notification-table-area .checkbox input:checked + label .custom-checkbox {
  background-color: #4dbfec;
}
.AddClientInformation .pure-form-area .buttons-wrapper {
  display: flex;
  align-items: center;
}

.AddClientInformation .pure-form-area .buttons-wrapper label {
  display: inline-block;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}
.AddClientInformation .pure-form-area .buttons-wrapper label:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.AddClientInformation .pure-form-area .buttons-wrapper #close-label,
.AddClientInformation .pure-form-area .buttons-wrapper #Save-Template-label {
  background-color: #c8d2dc;
  margin-right: 20px;
}
.table-container {
  margin-top: 1.5rem;
  margin-bottom: 4.5rem;
}
.table-container > p {
  margin-bottom: 1.5rem;
}
.table-area {
  display: grid;
  grid-template-columns: 0.7fr 0.6fr 1fr;
  border: 1px solid #c8d2dc;
  border-radius: 10px;
}
.table-area > div > div:first-child {
  background-color: #f4f6f9;
}
.table-area > div > div {
  padding: 0rem 1.5rem;
  height: 60px;
  border-bottom: 1px solid #c8d2dc;
  border-right: 1px solid #c8d2dc;
  display: flex;
  align-items: center;
}
.table-area > div > div:last-child {
  border-bottom: none;
}
.table-area > div:last-child > div {
  border-right: none;
}
.table-area > div > div input {
  width: 100%;
  border: none;
  font-size: 15px;
  outline: none;
  height: 90%;
}
.table-area > div > div textarea {
  background: transparent !important;
  height: 50px !important;
  font-size: 15px !important;
  padding: 0px !important;
  outline: none !important;
  border-radius: 0px !important;
}
.table-area > div > div textarea::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1100px) {
  .main-form-area-content {
    margin-bottom: 6rem;
  }
  .AddNewCurrentProgram .select-container,
  .IndividualSupportPlan .select-container,
  .BehaviourSupportPlan .select-container {
    flex-direction: column;
  }
  .AddNewCurrentProgram .select-container > *:first-child,
  .IndividualSupportPlan .select-container > *:first-child,
  .BehaviourSupportPlan .select-container > *:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper > * {
    width: 30% !important;
  }
}
@media screen and (max-width: 900px) {
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    align-items: flex-start;
  }
}
@media screen and (max-width: 950px) {
  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan .profile-information-content .profile-about,
  .BehaviourSupportPlan .profile-information-content .profile-about {
    width: 100% !important;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    flex-direction: row !important;
  }
}
@media screen and (max-width: 780px) {
  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan .profile-information-content .profile-about,
  .BehaviourSupportPlan.profile-information-content .profile-about {
    grid-template-columns: 1fr;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    grid-column: span 1 !important;
  }
}
@media screen and (max-width: 850px) {
  .AddClientInformation .main-form-area-content {
    grid-template-columns: 1fr;
  }
  .AddClientInformation .navigation-area ul li {
    text-align: left;
  }
}
@media screen and (max-width: 750px) {
  .AddClientInformation .pure-form-area .buttons-wrapper {
    flex-direction: column-reverse;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper #close-label,
  .AddClientInformation .pure-form-area .buttons-wrapper #Save-Template-label {
    margin-right: 0px;
    margin-top: 10px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper > * {
    min-width: 100%;
  }

  .select-wrapper {
    min-width: 100% !important;
  }
  .selecters-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .selecters-grid > * {
    grid-column: span 2 !important;
  }
}
@media screen and (max-width: 600px) {
  .selecters-grid {
    grid-template-columns: 1fr !important;
  }
  .selecters-grid > * {
    grid-column: span 1 !important;
  }
}

@media screen and (max-width: 525px) {
  .input-collection.d-flex {
    flex-direction: column;
  }
  .input-collection.d-flex > * {
    margin-right: 0px;
    width: 100%;
  }
  .input-collection.d-flex > .left-side {
    margin-right: 0px !important;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 470px) {
  .AddClientInformation .top-area-content a {
    position: static;
    margin-bottom: 0px;
    display: block;
  }

  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan.profile-information-content .profile-about,
  .BehaviourSupportPlan .profile-information-content .profile-about {
    min-width: 100% !important;
    grid-template-columns: 1fr !important;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    flex-direction: column !important;

    min-width: 100% !important;
    width: 100% !important;
  }
  .AddNewCurrentProgram
    .profile-information-content
    .profile-about
    > *
    .input-wrapper,
  .IndividualSupportPlan
    .profile-information-content
    .profile-about
    > *
    .input-wrapper,
  .BehaviourSupportPlan
    .profile-information-content
    .profile-about
    > *
    .input-wrapper {
    width: 100%;
  }
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    margin-top: 0px;
  }
  .AddClientInformation .profile-about .input-container {
    flex-direction: column;
  }
  .AddClientInformation .profile-about label {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    display: block;
  }
}
@media screen and (max-width: 370px) {
  .AddClientInformation .top-area-content h1 {
    font-size: 18px;
  }
  .AddClientInformation .main-heading p,
  .AddClientInformation .navigation-area ul li,
  .AddClientInformation .pure-form-area textarea,
  .AddClientInformation .pure-form-area label {
    font-size: 14px;
  }
  .AddClientInformation .main-heading img {
    width: 25px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper {
    flex-direction: column-reverse;
  }

  .AddClientInformation .main-form-area-content {
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 300px) {
  .AddClientInformation .main-heading p {
    text-align: center;
  }
}

.behaviour-form-area {
  background: #f2f2f2;
  padding: 4rem 0rem;
}
.behaviour-form-content-area {
  width: 85%;
  background: #fff;
  padding: 3rem;
  border-radius: 10px;
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: 250px 200px 100px 60px;
}
.behaviour-form-content-area .select-container {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-top: 0px;
}
.behaviour-form-content-area .select-container > * {
  flex: 0 1;
}
.behaviour-form-content-area .select-container label,
.behaviour-form-content-area .input-collection label {
  margin-bottom: 10px;
  display: block;
}

.behaviour-form-content-area > *:nth-child(2),
.behaviour-form-content-area > *:nth-child(4) {
  grid-column: span 2;
}
.behaviour-form-content-area > *:nth-child(5),
.behaviour-form-content-area > *:nth-child(6),
.behaviour-form-content-area > *:nth-child(7) {
  grid-column: span 3;
}

.behaviour-form-content-area .input-collection textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 10px;
  padding: 1rem;
  background: #fafbfc;
  border: 1px solid #e6e6e6;
  resize: none;
  font-family: "Rubik", sans-serif;
}
.behaviour-form-content-area .input-wrapper {
  height: 55px !important;
  border: 1px solid #e6e6e6;
}
.behaviour-form-content-area .input-wrapper > input {
  width: 100%;
  flex: 1 1;
  margin-left: 10px;
  height: 90%;
  border: none;
  outline: none;
  background: transparent;
}
.behaviour-form-content-area .checkbox-container {
  margin-top: 1.5rem;
}
.behaviour-form-content-area .checkbox-container > * + * {
  margin-left: 4rem;
}
.behaviour-form-content-area .checkbox-container,
.behaviour-form-content-area .checkbox-container label {
  display: flex;
  align-items: center;
}
.behaviour-form-content-area .button-wrapper {
  display: flex;
  align-items: center;

  justify-content: center;
}
.behaviour-form-content-area .button-wrapper input {
  display: none;
}
.behaviour-form-content-area .button-wrapper label {
  width: 180px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4dbfec;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}

.behaviour-form-content-area .added-behaviour-wrapper p {
  margin-bottom: 2rem;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-wrapper {
  background: #f4f6f9;
  padding: 2.5rem 2rem;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-content {
  height: 350px;
  overflow: auto;
  background: #fff;
  padding: 0.6rem 1.5rem;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content::-webkit-scrollbar {
  width: 7px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: #6db8e3;
  border-radius: 99px;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-content li {
  padding: 1.5rem 1rem;
  border-radius: 15px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li.active {
  background: #f6f8fa;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li.active {
  position: relative;
}

.delete-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  color: red;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li
  .delete-icon {
  cursor: pointer;
}
.behaviour-form-content-area .added-behaviour-wrapper .scroll-content li {
  position: relative;
}
.behaviour-form-content-area .added-behaviour-wrapper .scroll-content label {
  border-radius: 20px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  label:hover {
  background-color: #ddd !important;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  input:checked
  + label {
  display: block;
  background-color: #ddd !important;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li:hover
  .delete-icon {
  color: #c62b2b;
}
.behaviour-form-content-area .buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.behaviour-form-content-area .buttons-wrapper input {
  display: none;
}
.behaviour-form-content-area .buttons-wrapper > * + * {
  margin-left: 2rem;
}
.behaviour-form-content-area .buttons-wrapper > label,
.behaviour-form-content-area .buttons-wrapper > a {
  width: 180px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}
.behaviour-form-content-area .buttons-wrapper > label:hover,
.behaviour-form-content-area .buttons-wrapper > a:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.behaviour-form-content-area .buttons-wrapper > .cancel-label {
  background: #c8d2dc;
  margin-left: 0px;
}
@media screen and (max-width: 1100px) {
  .behaviour-form-content-area {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 780px) {
  .behaviour-form-content-area {
    grid-template-rows: 40px 200px 50px 50px 50px 50px;
  }
  .behaviour-form-content-area > * {
    grid-column: span 3;
  }
  .behaviour-form-content-area > *:nth-child(2),
  .behaviour-form-content-area > *:nth-child(4) {
    grid-column: span 3;
  }
  .behaviour-form-content-area > *:nth-child(5),
  .behaviour-form-content-area > *:nth-child(6),
  .behaviour-form-content-area > *:nth-child(7) {
    grid-column: span 3;
  }
  .behaviour-form-content-area .buttons-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .behaviour-form-content-area .buttons-wrapper > *,
  .behaviour-form-content-area .button-wrapper label {
    width: 100% !important;
  }
  .behaviour-form-content-area .buttons-wrapper > * + * {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .behaviour-form-content-area {
    width: 90%;
    padding: 1.5rem;
  }
  .behaviour-form-content-area .added-behaviour-wrapper .scroll-wrapper {
    padding: 0.5rem 0.5rem;
  }
}
@media screen and (max-width: 420px) {
  .behaviour-form-content-area .checkbox-container > * + * {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 300px) {
  .behaviour-form-content-area .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .behaviour-form-content-area .checkbox-container > * + * {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}

.notification-wrapper {
  background-color: #f2f2f2;
  padding: 4rem 0rem;
}

.notification-wrapper-content {
  background-color: #fff;
  padding: 1rem;
  padding-bottom: 20rem;
  border-radius: 15px;
}
.notification-wrapper-content .top-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.notification-table-top > div > h4 {
  text-align: left;
  padding-bottom: 0px;
}
.drop-down-area {
  position: relative;
}
.drop-down-area .checkbox-icon-wrapper {
  cursor: pointer;
}
.drop-down-area ul {
  position: absolute;
  top: 96%;
  left: -20px;
  display: none;
  background-color: #fff;
  z-index: 222;
  width: 200px;
  border-radius: 15px;
  border: 1px solid #ddd;
}
.drop-down-area ul.active {
  display: block;
}
.drop-down-area ul li:first-child label {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.drop-down-area ul li:last-child label {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.drop-down-area ul li input:checked + label {
  background-color: #4dbfec;
  color: #fff;
}
.drop-down-area ul li input {
  display: none;
}
.drop-down-area ul li label {
  padding: 1rem;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.notification-wrapper-content .top-area > * {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notification-wrapper-content .top-area > * h3 {
  flex: 1 1;
  font-weight: 500;
  font-size: 15px;
  margin: 0rem 10px;
}
.notification-wrapper-content .top-area > * {
  background-color: #f4f6f9;
  padding: 1rem;
}
.notification-wrapper-content .top-area > *.active {
  background-color: #fff;
}
.notification-wrapper-content .top-area > *.active h3,
.notification-wrapper-content .top-area > *.active i {
  color: #4dbfec;
}

.notification-wrapper-content .top-area > *.active .badge {
  background-color: #d9f2fb;
  color: #4dbfec;
}
/* D9F2FB */
.notification-wrapper-content .top-area > * .badge {
  padding: 0.3rem 1rem;
  background-color: #d6dfe8;
  border-radius: 99px;
  font-size: 15px;
}
.notification-wrapper .notification-table-area {
  margin-top: 3rem;
}
.notification-wrapper .notification-table-area input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.notification-wrapper .notification-table-area .notification-table-top {
  padding: 0rem 1.5rem;
}

.notification-wrapper .notification-table-area .notification-table-top,
.notification-wrapper .notification-table-area .notification-table-body-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
}

.notification-wrapper .notification-table-area .notification-table-top .col-1,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-1,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  label {
  display: flex;
  align-items: center;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  + * {
  margin-left: 20px;
}
.notification-wrapper
  .notification-table-area
  .notification-table-top
  .col-1
  > *
  + *,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-1
  > *
  + * {
  margin-left: 10px;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  form {
  grid-column: span 4;
  display: none;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  form.active {
  display: block;
}
.text-area-wrapper {
  position: relative;
}
.text-area-wrapper input {
  display: none;
}
.text-area-wrapper label {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  background-color: #c8d2dc;
  width: 65px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 45px;
  color: #fff;
  border-radius: 10px;
}
.text-area-wrapper > i {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #c2cad2;
}

.notification-table-body-row i.fa-pen {
  color: #c2cad2;
}

.notification-table-body-row form textarea {
  text-indent: 30px;
  width: 100%;
  height: 165px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-top: 10px;
  background-color: #f6f8fa;
  border-radius: 15px;

  resize: none;
  border: none;
  font-family: "Rubik", sans-serif;
  outline: none;

  padding: 1rem;
}

.notification-table-body-area {
  margin-top: 1rem;
  border-radius: 15px;
  border: 1px solid #c8d2dc;
}

.notification-table-body-area .notification-table-body-row {
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #c8d2dc;
  position: relative;
}
.notification-table-body-area .notification-table-body-row:last-child {
  border-bottom: none;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row:first-child.active {
  border-top-left-radius: 10px;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row.active::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #4dbfec;
  left: 0;
  border-top-left-radius: 15px;
  top: 0;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row.active
  .col-1
  p {
  font-weight: 600;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  label
  p {
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .notification-wrapper {
    overflow: auto;
  }
  .notification-wrapper-content {
    min-width: 1160px;
    margin: 0rem 2rem;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1100px) {
  .profile-information-content {
    padding: 3rem 0rem;
  }
}

.NotesBodyArea {
  background-color: #f2f2f2;
  padding: 3rem 0rem;
  padding-bottom: 10rem;
}

.NotesBodyAreaBox {
  padding: 2rem 2rem;
  width: 90%;
  margin: 0rem auto;
  background-color: #fff;
  border-radius: 10px;
}

.NotesBodyAreaBoxContentArea {
  display: grid;
  grid-template-columns: 24% 1fr;
  grid-gap: 2rem;
  margin-top: 3rem;
}
.dropdownWrapper {
  margin-bottom: 2rem;
}
.dropdownWrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
}

/* NotesWritingAea */
.NotesWritingAea,
.RawDataCommon {
  border: 1px solid #707070;
  border-radius: 20px;
}
.NotesWritingAea .top {
  position: relative;
  text-align: center;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #707070;
}
.RawDataCommon .top {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #707070;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.NotesWritingAea .top h3 {
  font-weight: 500;
  font-size: 22px;
}
.NotesWritingAea .top button,
.buttonWrapper button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
  background-color: #4dbfec;
  border: none;
  color: #fff;
  padding: 0.8rem 1.4rem;
  border-radius: 99px;
  cursor: pointer;
  font-size: 14px;
}

.instructor_observerd {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
}
.instructor_observerd p {
  font-weight: 500;
  font-size: 18px;
}
.instructor_observerd input {
  width: 100%;
  flex: 1 1;
  margin-left: 1rem;
  font-size: 18px;
  border: none;
  outline: none;
}

.textareaWrapper {
  padding: 1rem;
}
.textareaWrapper textarea {
  width: 100%;
  height: 550px;
  font-size: 18px;
  border: none;
  resize: none;
  outline: none;
}

/* buttonWrapper */
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonWrapper > * + * {
  margin-left: 1rem;
}
.buttonWrapper button {
  position: static;
  min-width: 160px;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.NotesBodyAreaBoxContentArea > div:first-child {
  display: flex;
  flex-direction: column;
  height: 50%;

  justify-content: space-between;
}

/* RawDataCommon */
.RawDataBody ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;

  align-items: center;
  border-bottom: 1px solid #707070;
}
.RawDataBody ul:last-child {
  border: none;
}
.Therapist .RawDataBody ul {
  grid-template-columns: 1fr 1fr 2fr;
}
.RawDataBody > ul li:last-child > p {
  position: absolute;
  left: 50%;

  -webkit-transform: translateX(-50%);

          transform: translateX(-50%);
}
.RawDataBody ul li {
  padding: 1.5rem 1rem;
  border-right: 1px solid #707070;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.RawDataBody ul li ul {
  display: flex;
  border: none;

  width: 90%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
}
.RawDataBody ul li ul li {
  padding: 0px;
  border: none;
  border-bottom: 0px;
}
.RawDataBody ul li:last-child {
  border-right-width: 0px;
}
.RawDataBody ul li p:last-child {
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .NotesBodyAreaBoxContentArea {
    grid-template-columns: 1fr;
  }
  .NotesWritingAea .top {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .NotesWritingAea .top button {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    position: static;
  }

  .RawDataCommon {
    min-width: 700px;
  }
}
@media screen and (max-width: 800px) {
  .RawDataWritingArea {
    overflow: auto;
  }
}
@media screen and (max-width: 560px) {
  .NotesWritingAea .top {
    align-items: center;
    flex-direction: column;
  }
  .NotesWritingAea .top button {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .instructor_observerd {
    flex-direction: column;
    align-items: flex-start;
  }
  .instructor_observerd input {
    margin-left: 0px;
    margin-top: 10px;
  }
  .buttonWrapper button {
    min-width: 50%;
  }
}
@media screen and (max-width: 420px) {
  .buttonWrapper {
    flex-direction: column;
  }
  .buttonWrapper button {
    min-width: 100%;
  }
  .buttonWrapper > * + * {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.ResultNavigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  list-style: none;
}
.ResultNavigation a {
  background-color: #f4f6f9;

  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
  border-top-right-radius: 10px;
}
.ResultNavigation a.active {
  background-color: transparent;
  color: #4dbfec;
}
.ResultDropDown {
  position: relative;
}
.ResultDropDown .head {
  border: 1px solid #c8d2dc;
  padding: 1.2rem 1rem;
  font-size: 18px;
  border-radius: 10px;
  color: #525252;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.ResultDropDown .head i {
  transition: 0.3s ease all;
}
.ResultDropDown ul {
  border: 1px solid #c8d2dc;
  border-radius: 16px;
  background-color: #fff;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  z-index: -2;
  transition: 0.3s ease all;
  opacity: 0;
}
.ResultDropDown ul li a {
  padding: 1.5rem 1rem;
  color: #000000;
  display: block;
  border-radius: 7px;
  font-size: 15px;
}
.ResultDropDown ul li a:hover {
  background-color: #4dbfec;
  color: #fff;
}
.ResultDropDown:hover .head i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ResultDropDown:hover ul {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  z-index: 2;
  opacity: 1;
}
@media screen and (max-width: 1000px) {
  .ResultNavigation {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  .ResultNavigation a {
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .ResultNavigation {
    grid-template-columns: 1fr;
  }
}

.PercentageArea {
  position: relative;
}
.PercentageArea > h1 {
  font-size: 35px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.PercentageArea > p {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  margin-top: 10px;
}

.percentage_bottom_area {
  margin-top: 6rem;
}
.percentage_bottom_area.extra_padding > * {
  padding-bottom: 3rem !important;
}
.percentage_bottom_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

/* ProbResultWrapper */
.ProbResultWrapper,
.MaintainResultWrapper {
  border: 1px solid #707070;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 3rem;
  margin-bottom: 14rem !important;
}
.ProbResultWrapper .head {
  display: grid;
  grid-template-columns: 150px 1fr 160px;
}
.MaintainResultWrapper .head,
.MaintainResultWrapper .body .row {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}
.ProbResultWrapper .head > * {
  font-size: 18px;
}
.ProbResultWrapper .head > *,
.ProbResultWrapper .body .row > *,
.MaintainResultWrapper .body .row > *,
.MaintainResultWrapper .head > * {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}
.ProbResultWrapper .body .row > *:first-child {
  font-weight: 400;
}
.ProbResultWrapper .head > * + *,
.ProbResultWrapper .body .row > * + *,
.MaintainResultWrapper .body .row > * + *,
.MaintainResultWrapper .head > * + * {
  border-left: 1px solid #707070;
}
.ProbResultWrapper .body .row {
  display: grid;
  border-top: 1px solid #707070;
  grid-template-columns: 150px repeat(18, 1fr) 160px;
}

.MaintainResultWrapper .body .row {
  border-top: 1px solid #707070;
}

.buttons_switching_area {
  display: flex;
  position: absolute;
  top: 8px;
  right: 0px;
  align-items: center;
  background-color: #d7dbec;
  padding: 0.2rem;
  border-radius: 5px;
}
.buttons_switching_area button {
  width: 70px;
  border: none;
  font-weight: 500;
  background-color: #fff;
  outline: none;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
}
.buttons_switching_area button.active {
  background-color: #4dbfec;
  color: #fff;
}

/* CumulativeList */
.CumulativeList {
  margin-top: 3rem;
  border: 1px solid #707070;
  border-radius: 30px;
}
.CumulativeList .head,
.CumulativeList .body_area ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.CumulativeList .head > *,
.CumulativeList .body_area ul > * {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.CumulativeList .body_area ul > * {
  height: 70px;
}
.CumulativeList .head > * + *,
.CumulativeList .body_area ul > * + * {
  border-left: 1px solid #707070;
}
.CumulativeList .body_area ul {
  border-top: 1px solid #707070;
}

.CumulativeGraph {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

/* table_big_caption */
.table_big_caption {
  text-align: center;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 25px;
}

/* graph_points_area */
.graph_points_area {
  position: absolute;
  top: 0px;
  right: 0px;
}
.graph_points_area > div {
  color: #707070;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.graph_points_area > div p {
  width: 120px;
  padding-right: 20px;
  text-align: right;
}
.graph_points_area > div span {
  min-width: 35px;
  height: 35px;
  display: inline-block;

  border-radius: 50%;
  background-color: #000;
}
.graph_points_area > div:last-child span {
  border-radius: 0px;
}

/* phase_change */
.phase_change {
  grid-column: span 2;

  margin-bottom: 3rem;
}
.phase_change > * {
  padding-bottom: 1rem !important;
}

/* BehaviourNotes */
.BehaviourNotes {
  border: 1px solid #ddd;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.BehaviourNotes ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.BehaviourNotes ul > * {
  height: 100px;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.BehaviourNotes > ul > li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.BehaviourNotes ul > * + * {
  border-left: 1px solid #ddd;
}
.BehaviourNotes .body_area > * {
  border-top: 1px solid #ddd;
}
.BehaviourNotes textarea {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  height: 100%;
  resize: none;
}
/* graph_wrapper */
.graph_wrapper {
  display: flex;
  align-items: center;
}
.graph_wrapper img {
  cursor: pointer;
}
.graph_wrapper > *:nth-child(1) {
  margin-right: 1rem;
}
.graph_wrapper > .CumulativeGraph {
  flex: 1 1;
}
.graph_wrapper > *:nth-child(3) {
  margin-left: 1rem;
}
@media screen and (max-width: 1300px) {
  .percentage_bottom_area {
    grid-gap: 3rem;
  }

  .percentage_bottom_area > * {
    padding-bottom: 3rem !important;
    grid-column: span 2;
  }
  .phase_change {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .ProbResultContainer,
  .MaintainResultContainer {
    overflow: auto;
  }
  .ProbResultWrapper,
  .MaintainResultWrapper {
    min-width: 1000px;
  }
  .graph_wrapper img {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .buttons_switching_area {
    position: static;
    display: flex;
    background-color: transparent;
    justify-content: center;
    margin-top: 2rem;
  }
  .BehaviourNotesWrapper {
    overflow: auto;
  }
  .BehaviourNotes {
    min-width: 800px;
  }
}
@media screen and (max-width: 700px) {
  .CumulativeListWrapper {
    overflow: auto;
  }
  .CumulativeList {
    min-width: 700px;
  }
  .graph_points_area {
    top: 50px;
  }
  .graph_points_area > div span {
    min-width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 500px) {
  .PercentageArea > h1 {
    font-size: 27px;
  }
  .table_big_caption {
    font-size: 20px;
  }
}
@media screen and (max-width: 340px) {
  .graph_points_area {
    top: 70px;
  }
}

.DropDownList_DropDownList__1TcZT {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1100px) {
  .DropDownList_DropDownList__1TcZT {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .DropDownList_DropDownList__1TcZT {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ResultBoxArea_ResultBoxArea__1o2ER {
  background-color: #f6f8fa;
  border-radius: 16px;
  padding: 4rem 2rem;
  padding-bottom: 1rem;
  display: flex;

  position: relative;
}
.ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 {
  position: absolute;
  top: -30px;
  height: 70px;
  color: #fff;
  background-color: #4dbfec;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 430px;
  padding: 1rem;
}
.ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 h1 {
  font-size: 25px;
}
.ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_list_wrapper__384uE {
  width: 100%;
}
.ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_list_wrapper__384uE p {
  font-size: 20px;
}
.ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_list_wrapper__384uE > * + * {
  margin-top: 1rem;
}
.ResultBoxArea_buttonWrapper__GdrfP {
  display: flex;
  align-items: center;
  margin-top: 2rem !important;
  justify-content: space-between;
}
.ResultBoxArea_buttonSingle__18fl4 {
  justify-content: center;
}
.ResultBoxArea_buttonWrapper__GdrfP button {
  color: #fff;
  border-radius: 99px;
  background-color: #4dbfec;
  border: none;
  outline: none;
  cursor: pointer;
  width: 197px;
  height: 38px;
}
@media screen and (max-width: 600px) {
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 {
    width: 320px;
  }
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 h1 {
    font-size: 20px;
  }
  .ResultBoxArea_buttonWrapper__GdrfP {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 {
    width: 250px;
  }
  .ResultBoxArea_buttonWrapper__GdrfP button {
    width: 100%;
  }
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 h1 {
    font-size: 17px;
  }
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_list_wrapper__384uE p {
    font-size: 17px;
    word-break: break-all;
  }
  .ResultBoxArea_buttonWrapper__GdrfP > * + * {
    margin-top: 10px;
  }
}
@media screen and (max-width: 400px) {
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 h1 {
    font-size: 14px;
  }
  .ResultBoxArea_ResultBoxArea__1o2ER .ResultBoxArea_box_title__18dM1 {
    width: 70%;
    height: 60px;
  }
}

.DropDownList_DropDownList__1k_SF {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1100px) {
  .DropDownList_DropDownList__1k_SF {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .DropDownList_DropDownList__1k_SF {
    grid-template-columns: repeat(1, 1fr);
  }
}

