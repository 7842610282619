.header-content form {
  display: flex;
  align-items: center;
}
.header-content form input {
  height: 45px;
  width: 200px;
  margin-right: 10px;
  padding: 0rem 1rem;
  border-radius: 99px;
  border: 2px solid #ddd;
  outline: none;
}
.profile-information-content,
.profile-information-content .profile-about {
  display: flex;
  align-items: center;
  position: relative;
}
.add-new-client-button {
  width: 250px;
  height: 50px;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  border: none;
  outline: none;
  border-radius: 99px;
  position: absolute;
  bottom: -40px;
  left: 0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s ease all;
}
.add-new-client-button:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.profile-information {
  padding: 1rem 0rem;
  background-color: #fff;
  z-index: 5;
  position: static;
  top: 70px;
}
.profile-information-content .profile-about {
  flex: 1;
  margin-left: 3rem;
  align-items: flex-start;
}
.profile-information-content .profile-about > * {
  flex: 1;
}
.profile-information-content .profile-about > div {
  max-width: 370px;
}
.profile-information-content .profile-about div small {
  color: #4dbfec;
  margin-bottom: 1rem;
  display: inline-block;
}
.profile-information-content .profile-about > div:first-child {
  padding-right: 2rem;
  border-right: 1px solid #e3e8ed;
}
.profile-information-content .profile-about > div:last-child {
  padding-left: 2rem;
}
.profile-information-content .profile-about div h1 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #0e0e0e;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.profile-information-content .profile-about div h1 span {
  font-size: 13px;
  font-weight: 300;
  color: #b3b3b3;
  margin-top: 4px;
}
.profile-information-content .profile-about div p.email {
  color: #b3b3b3;
}
.profile-information-content .profile-ea-logo-white {
  width: 200px;
}
.profile_image_wrapper,
.profile_ea_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile_image_wrapper i,
.profile_ea_wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
  transition: 0.3s ease all;
  pointer-events: none;
  opacity: 0;
}
.profile-information-content .profile-image {
  width: 170px;
  border-radius: 20px;
  transition: 0.3s ease all;
}
.profile_ea_wrapper img {
  cursor: pointer;
}
.profile-information-content .profile-image:hover,
.profile_ea_wrapper img:hover {
  filter: brightness(50%);
}
.profile-information-content .profile-image:hover + i,
.profile_ea_wrapper img:hover + i {
  opacity: 1;
}
.profile-information-content .login-information {
  display: flex;
  align-items: center;
}
.profile-information-content .login-information > * {
  flex: 1;
  display: flex;
  align-items: center;
}
.profile-information-content .login-information > * i {
  margin-right: 10px;
  color: #a6a6a6;
}
.clients-working-section {
  background-color: #f2f2f2;
  padding: 4rem 0rem;
}
.clients-working-section .clients-box,
.edit-client-box {
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  margin-bottom: 3rem;
}
.clients-working-section .clients-box-top,
.clients-working-section .edit-client-box .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clients-working-section .clients-box-top button,
.edit-client-box .close-button {
  color: #4dbfec;
  border: 1px solid #4dbfec;
  border-radius: 99px;
  width: 140px;
  height: 45px;
  font-size: 17px;
  cursor: pointer;
  background-color: transparent;
}
.edit-client-box .close-button {
  background-color: #4dbfec;
  color: #fff;
}
.clients-working-section .clients-box-top p,
.clients-working-section .edit-client-box .top p {
  font-weight: 400;
  font-size: 17px;
}
.clients-working-section .clients-box-top p i {
  color: #4dbfec;
  margin-right: 10px;
}
.clients-working-section .edit-client-box .top p i {
  margin-right: 10px;
}
.clients-working-section .edit-client-box .top {
  color: #bdbbbb;
}
.clients-working-section .clients-box-top p span,
.clients-working-section .edit-client-box .top p span {
  margin-left: 2rem;
  font-size: 16px;
  color: #bdbbbb;
}
.client-information-edit {
  /* display: none; */
}
.client-information-edit {
  display: block;
}
.clients-working-section .edit-options,
.client-information-edit .edit-boxes {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-auto-rows: 200px;
  margin-top: 1rem;
  grid-gap: 1rem;
}
.client-information-edit .edit-boxes > *,
.clients-working-section .edit-options > * {
  grid-column: span 2;
}
.clients-working-section .edit-options .edit-option,
.client-information-edit .edit-boxes .edit-option {
  border: 1px solid #cfd8e0;
  border-radius: 10px;
  border-top: 6px solid #4dbfec;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.client-information-edit .edit-boxes .edit-option > *,
.clients-working-section .edit-options .edit-option > * {
  pointer-events: none;
}
.clients-working-section .edit-options .edit-option,
.edit-boxes .edit-option {
  position: relative;
}
.clients-working-section .edit-options .edit-option i,
.edit-boxes .edit-option i {
  position: absolute;
  bottom: 24px;
  font-size: 1.5rem;
}
.clients-working-section .edit-options .edit-option .badge-notify {
  width: 40px;
  height: 30px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 5px;
}
.clients-working-section .edit-options .edit-option p,
.client-information-edit .edit-boxes .edit-option p {
  flex: 1;
  display: flex;
  align-items: center;
}
.clients-working-section .edit-options .edit-option:first-child {
  border-top-color: #6db8e3;
}
.clients-working-section .edit-options .edit-option:first-child i,
.edit-boxes .edit-option:first-child i {
  color: #6db8e3;
}
.clients-working-section .edit-options .edit-option:first-child .badge-notify {
  background-color: #6db8e3;
}

.clients-working-section .edit-options .edit-option:nth-child(2) {
  border-top-color: #afd458;
}

.clients-working-section .edit-options .edit-option:nth-child(2) i {
  color: #afd458;
}

.clients-working-section .edit-options .edit-option:nth-child(2) .badge-notify {
  background-color: #afd458;
}
.clients-working-section .edit-options .edit-option:nth-child(3) {
  border-top-color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(3) i {
  color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(3) .badge-notify {
  background-color: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(4) {
  border-top-color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(4) i,
.edit-boxes .edit-option:nth-child(2) i {
  color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(4) .badge-notify {
  background-color: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(5) {
  border-top-color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(5) i,
.edit-boxes .edit-option:nth-child(3) i {
  color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(5) .badge-notify {
  background-color: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(6) {
  border-top-color: #f0595e;
}

.clients-working-section .edit-options .edit-option:nth-child(6) i,
.edit-boxes .edit-option:nth-child(4) i {
  color: #f0595e;
}
.edit-boxes .edit-option:nth-child(5) i {
  color: #aaafb2;
}

.clients-working-section .edit-options .edit-option:nth-child(6) .badge-notify {
  background-color: #f0595e;
}

.clients-working-section .edit-options .edit-option:nth-child(7) {
  border-top-color: #214fe7;
}
.clients-working-section .edit-options .edit-option:nth-child(7) i {
  color: #214fe7;
}
.clients-working-section .edit-options .edit-option:nth-child(7) .badge-notify {
  background-color: #214fe7;
}

.clients-working-section .edit-options .edit-option:nth-child(8) {
  border-top-color: #e984bf;
}
.clients-working-section .edit-options .edit-option:nth-child(8) i {
  color: #e984bf;
}
.clients-working-section .edit-options .edit-option:nth-child(8) .badge-notify {
  background-color: #e984bf;
}

.clients-working-section .edit-options .client_info {
  box-shadow: 5px 5px 10px #6db8e3;
}

.clients-working-section .edit-options .ConsentForm {
  box-shadow: 5px 5px 10px #afd458;
}
.clients-working-section .edit-options .AssessmentGraphs {
  box-shadow: 5px 5px 10px #81d4b9;
}
.clients-working-section .edit-options .IndividualPlan {
  box-shadow: 5px 5px 10px #e79121;
}

.clients-working-section .edit-options .CurrentPrograms {
  box-shadow: 5px 5px 10px #9f7dd9;
}

.clients-working-section .edit-options .BehaviouralManagement {
  box-shadow: 5px 5px 10px #f0595e;
}

.clients-working-section .edit-options .edit-option::after {
  content: "";
  width: 100%;
  height: 3px;
  top: 110%;
  left: 0;
  position: absolute;
}
.clients-working-section .edit-options .edit-option:nth-child(1).active::after {
  background: #6db8e3;
}
.clients-working-section .edit-options .edit-option:nth-child(2).active::after {
  background: #afd458;
}
.clients-working-section .edit-options .edit-option:nth-child(3).active::after {
  background: #81d4b9;
}
.clients-working-section .edit-options .edit-option:nth-child(4).active::after {
  background: #e79121;
}
.clients-working-section .edit-options .edit-option:nth-child(5).active::after {
  background: #9f7dd9;
}
.clients-working-section .edit-options .edit-option:nth-child(6).active::after {
  background: #f0595e;
}
.clients-working-section .edit-options .edit-option:nth-child(7).active::after {
  background: #214fe7;
}

.clients-working-section .edit-client-box .bottom {
  margin-top: 1rem;
}
.clients-working-section .edit-client-box .bottom p {
  font-size: 17px;
}
.client-information-edit .edit-boxes .edit-option {
  border: 1px dashed #aaafb2;
  color: #000;
}

/* pop ups */

.show-notifications {
  background: #f4f6f9;
  margin-top: 2rem;
  padding: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  border-radius: 10px;

  grid-gap: 10px;
  overflow: auto;
  max-height: 330px;
  padding-top: 5rem;
  position: relative;
}
.show-notifications form {
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 20px;
}
.show-notifications .input_wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  width: 300px;
  padding: 0rem 15px;
  margin-right: 10px;
}
.show-notifications .input_wrapper input {
  flex: 1;
  margin-left: 5px;
  border: none;
  font-size: 16px;
  outline: none;
}
.show-notifications .select_wrapper {
  background-color: #fff;
  border-radius: 10px;
  border-radius: 10px;
  padding: 0rem 10px;
  height: 50px;
}
.show-notifications .select_wrapper select {
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
}
.show-notifications::-webkit-scrollbar {
  display: none;
}
.show-notifications .notification-box {
  border: 1px solid #dde2e7;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: #000;
  align-items: flex-start;
  border-radius: 10px;
  min-height: 200px;
  position: relative;
}
.show-notifications .notification-box .top-area {
}
.show-notifications .notification-box .not_locked {
  justify-content: flex-end !important;
}
.show-notifications .add-box,
.show-notifications .edit-option.upload-files-consent,
.show-notifications .edit-option.upload-files-assessment {
  border: 1px dashed #4dbfec;
  background: #f6fcff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-notifications .add-box.active {
  display: flex !important;
}
.show-notifications .add-box i {
  color: #4dbfec;
  font-size: 60px;
}
.show-notifications .edit-option.upload-files-consent,
.show-notifications .edit-option.upload-files-assessment {
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem 2rem;
}
.show-notifications .edit-option.upload-files-consent p,
.show-notifications .edit-option.upload-files-assessment p {
  text-align: center;
  color: #000000;
}
.show-notifications .edit-option.upload-files-consent i,
.show-notifications .edit-option.upload-files-assessment i {
  color: #aaafb2;
  font-size: 40px;
}
.show-notifications .notification-box {
  min-height: 220px;
}
.show-notifications .notification-box a {
  color: #000 !important;
}
.show-notifications .notification-box .top-area {
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
}

.show-notifications .notification-box .top-area .action-image {
  width: 23px;

  background-image: url(../../img/unlock.png);
  background-position: center;
  background-size: contain;
  height: 31px;
  cursor: pointer;
  background-repeat: no-repeat;
}
.show-notifications .notification-box .top-area .action-image.active {
  background-image: url(../../img/lock.png);
}
.show-notifications .notification-box .top-area i {
  color: #c8d2dc;
}
.show-notifications .notification-box .presentation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 0rem 0.4rem;
  width: 100%;
}
.show-notifications .notification-box .presentation .buttons-wrapper {
  width: 100% !important;
  margin: 10px 0px;

  flex: 1;
}
.show-notifications .notification-box .presentation .buttons-wrapper a {
  display: block;
  width: 100%;
  font-size: 14px;
  background-color: #00c1f1;
  border-radius: 99px;
  text-align: center;
  color: #fff !important;
  padding: 0.6rem 0.5rem;
}
.show-notifications
  .notification-box
  .presentation
  .buttons-wrapper
  a:first-child {
  margin-bottom: 5px;
}
.show-notifications .notification-box h2 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.7rem;
}

.show-notifications .notification-box span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 105%;
  color: #a2a2a2;
}

.show-notifications .notification-box .edit-detail h6 {
  margin-bottom: 5px;
  color: #a5b0bb;
}
.show-notifications .notification-box .edit-detail p {
  word-break: break-all;
  font-size: 14px;
}
@media screen and (max-width: 1100px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes {
    grid-template-columns: repeat(5, 1fr);
  }
  .client-information-edit .edit-boxes > *,
  .clients-working-section .edit-options > * {
    grid-column: span 1;
  }
  .profile-information-content .profile-ea-logo-white {
    display: none;
  }
  .show-notifications {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 900px) {
  .profile-information {
    padding-bottom: 5rem;
  }
  .add-new-client-button {
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
  }
  .profile-information-content {
    flex-direction: column-reverse;
  }
  .profile-information-content .profile-about {
    margin-left: 0px;
    flex-direction: column;
    /* align-items: center; */
    align-items: flex-start;
  }
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    padding-right: 0px;
    border: none;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .profile-information-content .profile-about > div:first-child {
    margin-bottom: 1rem;
  }
  .profile-information-content .profile-about > div:last-child {
    padding-left: 0px;
  }
  .profile-information-content .profile-about div small {
    margin-bottom: 0.5rem;
  }
  .profile-information-content .login-information {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .profile-information-content .login-information > * + * {
    margin-top: 6px;
  }
}
@media screen and (max-width: 850px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 730px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(3, 1fr);
  }
  .main-header-nav a {
    flex-direction: column;
    align-items: center;
  }
  .main-header-nav a i {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 630px) {
  .header-content form input {
    display: none;
  }
  .main-header-nav a p {
    display: none;
  }
  .header-content nav {
    padding: 1.5rem 4rem;
  }
  .main-header-nav a i {
    margin: 0px !important;
  }
}
@media screen and (max-width: 540px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(2, 1fr);
  }
  .show-notifications {
    /* padding: 1rem; */

    padding-top: 10rem;
  }

  .show-notifications form {
    flex-direction: column;
    width: 90%;
    right: 50%;
    transform: translateX(50%);
  }
  .show-notifications .select_wrapper {
    width: 100%;
    margin-top: 10px;
  }
  .show-notifications .input_wrapper {
    margin-right: 0px;
    width: 100%;
  }
  .show-notifications .select_wrapper select {
    width: 100%;
  }
  .profile-information-content .profile-image {
    width: 130px;
  }
  .add-new-client-button {
    width: 190px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .clients-working-section .clients-box-top,
  .clients-working-section .edit-client-box .top {
    flex-direction: column;
  }
  .clients-working-section .clients-box-top > * + *,
  .clients-working-section .edit-client-box .top > * + * {
    margin-top: 1rem;
  }
  .clients-working-section .clients-box-top p,
  .clients-working-section .edit-client-box .top p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .clients-working-section .clients-box-top p span,
  .clients-working-section .edit-client-box .top p span {
    margin-left: 0px;
  }
}
@media screen and (max-width: 370px) {
  .clients-working-section .edit-options,
  .client-information-edit .edit-boxes,
  .show-notifications {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PopUpConfirm {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopUpConfirm > * {
  background-color: #fff;
  width: 550px;
  height: 400px;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.PopUpConfirm h1 {
  font-size: 22px;
  text-align: center;
}
.PopUpConfirm .buttons_wrapper {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.PopUpConfirm .buttons_wrapper > * + * {
  margin-left: 10px;
}
.PopUpConfirm .buttons_wrapper button {
  width: 100px;
  height: 50px;
  border-radius: 10px;
}
