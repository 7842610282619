.DropDownList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1100px) {
  .DropDownList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .DropDownList {
    grid-template-columns: repeat(1, 1fr);
  }
}
