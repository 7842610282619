.ResultBoxArea {
  background-color: #f6f8fa;
  border-radius: 16px;
  padding: 4rem 2rem;
  padding-bottom: 1rem;
  display: flex;

  position: relative;
}
.ResultBoxArea .box_title {
  position: absolute;
  top: -30px;
  height: 70px;
  color: #fff;
  background-color: #4dbfec;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 430px;
  padding: 1rem;
}
.ResultBoxArea .box_title h1 {
  font-size: 25px;
}
.ResultBoxArea .list_wrapper {
  width: 100%;
}
.ResultBoxArea .list_wrapper p {
  font-size: 20px;
}
.ResultBoxArea .list_wrapper > * + * {
  margin-top: 1rem;
}
.buttonWrapper {
  display: flex;
  align-items: center;
  margin-top: 2rem !important;
  justify-content: space-between;
}
.buttonSingle {
  justify-content: center;
}
.buttonWrapper button {
  color: #fff;
  border-radius: 99px;
  background-color: #4dbfec;
  border: none;
  outline: none;
  cursor: pointer;
  width: 197px;
  height: 38px;
}
@media screen and (max-width: 600px) {
  .ResultBoxArea .box_title {
    width: 320px;
  }
  .ResultBoxArea .box_title h1 {
    font-size: 20px;
  }
  .buttonWrapper {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .ResultBoxArea .box_title {
    width: 250px;
  }
  .buttonWrapper button {
    width: 100%;
  }
  .ResultBoxArea .box_title h1 {
    font-size: 17px;
  }
  .ResultBoxArea .list_wrapper p {
    font-size: 17px;
    word-break: break-all;
  }
  .buttonWrapper > * + * {
    margin-top: 10px;
  }
}
@media screen and (max-width: 400px) {
  .ResultBoxArea .box_title h1 {
    font-size: 14px;
  }
  .ResultBoxArea .box_title {
    width: 70%;
    height: 60px;
  }
}
