.InsertPdf > form {
  width: 90%;
  margin: 4rem auto;
}
.InsertPdf > form input {
  display: none;
}

.InsertPdf > form label {
  width: 100%;
  height: 100vh;
  background-color: #cbe9e4;
  border: 1px solid #707070;
  cursor: pointer;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  color: #707070;
}
.InsertPdf > form label p {
  text-align: center;
}
.InsertPdf > form label p span {
  display: block;

  margin-top: 5px;
}
