.AddClientInformation .profile-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem !important;
}
#checkbox-error-correction2:checked + label i {
  color: #4cbfed;
}
.bypassEnter {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  pointer-events: none;
}
.by_pass_wrapper:checked + label .bypassEnter {
  pointer-events: unset;
}
.AddClientInformation .profile-information {
  padding-top: 0px !important;
}
.AddClientInformation .top-area {
  border-bottom: 1px solid #f2f2f2;
  padding: 1.5rem 0rem;
}
.AddClientInformation .top-area-content {
  width: 85%;
  margin: 0rem auto;
  text-align: center;
  position: relative;
}
.AddClientInformation .top-area-content a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #7b8c9c;
}
.AddClientInformation .top-area-content a i {
  font-size: 20px;
}
.AddClientInformation .top-area-content h1 {
  font-size: 22px;
}
@media screen and (min-width: 1400px) {
  .AddClientInformation .profile-about {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .AddClientInformation .profile-about > *:nth-child(2) {
    grid-column: span 2;
    width: 45%;
  }
}
.AddNewCurrentProgram .profile-information-content .profile-about,
.IndividualSupportPlan .profile-information-content .profile-about,
.BehaviourSupportPlan .profile-information-content .profile-about {
  grid-template-columns: 1fr 1fr 1fr;
}
.AddNewCurrentProgram
  .profile-information-content
  .profile-about
  > *:nth-child(1),
.IndividualSupportPlan
  .profile-information-content
  .profile-about
  > *:nth-child(1),
.BehaviourSupportPlan
  .profile-information-content
  .profile-about
  > *:nth-child(1) {
  grid-column: span 2;
  width: 100%;
}
.AddNewCurrentProgram .profile-information-content .profile-about label,
.IndividualSupportPlan .profile-information-content .profile-about label,
.BehaviourSupportPlan .profile-information-content .profile-about label {
  width: 100px;
}
.AddNewCurrentProgram .select-container,
.IndividualSupportPlan .select-container,
.BehaviourSupportPlan .select-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.AddNewCurrentProgram .select-container > *,
.IndividualSupportPlan .select-container > *,
.BehaviourSupportPlan .select-container > * {
  flex: 1;
  width: 100%;
}
.AddNewCurrentProgram .select-container > *:first-child,
.IndividualSupportPlan .select-container > *:first-child,
.BehaviourSupportPlan .select-container > *:first-child {
  margin-right: 10px;
}
.AddNewCurrentProgram .profile-information-content .profile-about,
.IndividualSupportPlan .profile-information-content .profile-about,
.BehaviourSupportPlan .profile-information-content .profile-about {
  padding-top: 3rem;
}
.AddClientInformation .profile-about .input-container {
  padding: 0px !important;
  border-right: none !important;
  max-width: 90%;
}
.AddClientInformation .profile-about .input-container.month label {
  font-weight: 400;
  font-size: 15px;
  color: #979797;
}
.AddClientInformation .profile-about label {
  width: 120px;
  color: #4dbfec;
  font-weight: 500;
  margin-right: 10px;
}
.AddClientInformation .profile-about .input-wrapper {
  display: flex;
  align-items: center;
  flex: 1;

  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0.2rem;
  max-width: 100% !important;
}

.AddClientInformation .profile-about .input-wrapper {
  color: #c2cad2;
}
.AddClientInformation .profile-about .input-wrapper input {
  width: 100%;
  border: none;
  font-family: "Rubik", sans-serif;
  flex: 1;
  outline: none;
  padding: 0rem 0.5rem;
}
.AddClientInformation .profile-about > .input-container {
  display: flex;
  align-items: flex-end;
}
.AddClientInformation .main-form-area {
  padding: 2rem 0rem;
  background-color: #f2f2f2;
}

.AddClientInformation .main-form-area-content {
  padding: 2rem;
  border-radius: 10px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 2rem;
}

.AddClientInformation .main-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.AddClientInformation .main-heading img {
  margin-right: 10px;
  width: 35px;
}

.AddClientInformation .main-heading p {
  color: #4dbfec;
}
.AddClientInformation .navigation-area ul {
  margin-top: 1rem;
  position: sticky;
  top: 80px;
}
.pure-form-area > span {
  position: relative;
  top: -100px;
}
.AddClientInformation .navigation-area ul li {
  cursor: pointer;
  text-align: right;
}
.AddClientInformation .navigation-area ul li a {
  text-decoration: none;
  color: #000;
  padding: 0.8rem 0.4rem;
  padding-right: 15px;
  width: 100%;
  display: inline-block;
  /* margin-top: -40px; */
}
.AddClientInformation .navigation-area ul li.save-template-button button {
  background-color: #4dbfec;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  padding: 0rem 2rem;
  border-radius: 15px;
}
.AddClientInformation .navigation-area ul li a.active {
  background-color: #f6f8fa;
  border-right: 5px solid #4dbfec;
  border-radius: 7px;
  font-weight: 600;
}
.AddClientInformation .pure-form-area .input-collection {
  padding: 2rem 0rem;
  border-bottom: 1px solid #dbdbdb;
}

.AddClientInformation .pure-form-area .input-collection.d-flex {
  display: flex;
  align-items: center;
}
.AddClientInformation .pure-form-area .input-collection.d-flex > div > label {
  margin-bottom: 10px;
}
.AddClientInformation .pure-form-area .input-collection.d-flex .left-side {
  margin-right: 30px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper .select-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c8d2dc;
  height: 45px;
  border-radius: 10px;
  padding: 0rem 1rem;
  cursor: pointer;
}

.select-wrapper .select-heading > * {
  color: #525252;
}
.select-wrapper .select-body,
.special-select-body {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: none;
}
.special-select-body {
  display: block;
  position: static;
}
.select-wrapper .select-body.active {
  display: block;
}
.select-wrapper .select-body > li:first-child,
.special-select-body > li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.select-wrapper .select-body > li:last-child,
.special-select-body > li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.select-wrapper .select-body > li:hover,
.special-select-body > li:hover {
  background-color: #4dbfec;
}

.select-wrapper .select-body > li a,
.select-wrapper .select-body li label,
.special-select-body > li a,
.special-select-body li label {
  width: 100%;
  display: block;
  color: #000;
  padding: 1rem 1rem;
  border-radius: 10px;
  font-weight: 200;
  cursor: pointer;
}

.select-wrapper .select-body li label:hover,
.special-select-body li label:hover {
  color: #fff;
}
/* behaviour-form-content-area */
.AddClientInformation
  .pure-form-area
  .input-collection
  .select-wrapper
  .select-body
  li
  input,
.select-wrapper .select-body li input,
.special-select-body li input {
  display: none;
}
.important-variable {
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-top: 1px solid #e8e8e8;
}
.important-variable:hover {
  background-color: #fff !important;
}
.important-variable .count {
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.8fr;
  width: 50%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  height: 50px;
}
.important-variable .count > * {
  display: flex;
  align-items: center;
  justify-content: center;
}
.important-variable .count .arrow-left {
  transform: rotate(270deg);
}
.important-variable .count .arrow-right {
  transform: rotate(270deg);
}
.important-variable .count .arrow > * {
  pointer-events: none;
}
.important-variable .count .arrow-left,
.important-variable .count .arrow-right {
  cursor: pointer;
}
.select-wrapper .Applicable-Assessments-select-body li {
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
}
.select-wrapper .Applicable-Assessments-select-body li input {
  display: block !important;
  width: 15px;
  height: 15px;
}
.select-wrapper .select-body > li:hover {
  color: #fff;
}
.selecters-grid {
  display: grid;
  width: 100%;

  margin-top: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
}
.selecters-grid > * {
  width: 100% !important;
  grid-column: span 2;
}

.AddClientInformation .pure-form-area .input-collection .right-side input {
  height: 47px;
  background-color: #f6f8fa;
  width: 100%;
  border: none;
  padding: 0rem 1rem;
  outline: none;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
}
.AddClientInformation .pure-form-area .input-collection.d-flex > * {
  flex: 1;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper,
.behaviour-form-content-area .input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f6f8fa;
  height: 45px;
  padding: 0rem 1rem;
  border-radius: 10px;
}
.AddClientInformation .pure-form-area .input-collection.text-input-area h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
.AddClientInformation .pure-form-area .input-collection.text-input-area p {
  color: #576068;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper input {
  width: 100%;
  flex: 1;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
}
.AddClientInformation .pure-form-area .input-collection .input-wrapper img {
  width: 20px;
}

.AddClientInformation .pure-form-area > *:first-child {
  padding-top: 0rem;
}

.AddClientInformation .pure-form-area label {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.AddClientInformation .pure-form-area label i {
  color: #6db8e3;
  font-size: 20px;
  margin-left: 10px;
}
.text-area-wrapper {
  position: relative;
}
.AddClientInformation .pure-form-area .input-wrapper > i {
  margin-left: 10px;
}

.AddClientInformation .pure-form-area .input-wrapper > input {
  margin-left: 10px;
}
.text-area-wrapper > i {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #c2cad2;
}

.pure-form-area i.fa-pen {
  color: #c2cad2;
}
.text-area-wrapper > textarea {
  text-indent: 30px;
}
.AddClientInformation .pure-form-area textarea {
  height: 165px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-top: 10px;
  background-color: #f6f8fa;
  border-radius: 15px;

  resize: none;
  border: none;
  font-family: "Rubik", sans-serif;
  outline: none;

  padding: 1rem;
}
.AddClientInformation .pure-form-area .checkbox input,
.BehaviourManagementTools .checkbox input,
.notification-table-area .checkbox input {
  display: none;
}
.AddClientInformation .pure-form-area .custom-checkbox,
.BehaviourManagementTools .custom-checkbox,
.notification-table-area .custom-checkbox {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
}
/* checkboxes */
.AddClientInformation .pure-form-area .checkboxes {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.AddClientInformation .pure-form-area .checkboxes > * {
  min-width: 100px;
  margin-top: 10px;
}

.AddClientInformation .pure-form-area .custom-checkbox i,
.BehaviourManagementTools .custom-checkbox i,
.notification-table-area .checkbox i {
  margin: 0;
  color: transparent;
  font-size: 14px;
}
.AddClientInformation .pure-form-area label p,
.BehaviourManagementTools label p {
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 20px;
}
.AddClientInformation
  .pure-form-area
  .checkboxes
  .checkbox
  input:checked
  + label
  .custom-checkbox
  i,
.BehaviourManagementTools
  .checkboxes
  .checkbox
  input:checked
  + label
  .custom-checkbox
  i,
#Error-Correction .checkbox input:checked + label .custom-checkbox i {
  color: #4dbfec !important;
}
.special-select-body > li > input:checked + label {
  background-color: #4dbfec;
  color: #fff;
}

.notification-table-area .checkbox input:checked + label i {
  color: #fff;
}
.notification-table-area .checkbox input:checked + label .custom-checkbox {
  background-color: #4dbfec;
}
.AddClientInformation .pure-form-area .buttons-wrapper {
  display: flex;
  align-items: center;
}

.AddClientInformation .pure-form-area .buttons-wrapper label {
  display: inline-block;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}
.AddClientInformation .pure-form-area .buttons-wrapper label:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.AddClientInformation .pure-form-area .buttons-wrapper #close-label,
.AddClientInformation .pure-form-area .buttons-wrapper #Save-Template-label {
  background-color: #c8d2dc;
  margin-right: 20px;
}
.table-container {
  margin-top: 1.5rem;
  margin-bottom: 4.5rem;
}
.table-container > p {
  margin-bottom: 1.5rem;
}
.table-area {
  display: grid;
  grid-template-columns: 0.7fr 0.6fr 1fr;
  border: 1px solid #c8d2dc;
  border-radius: 10px;
}
.table-area > div > div:first-child {
  background-color: #f4f6f9;
}
.table-area > div > div {
  padding: 0rem 1.5rem;
  height: 60px;
  border-bottom: 1px solid #c8d2dc;
  border-right: 1px solid #c8d2dc;
  display: flex;
  align-items: center;
}
.table-area > div > div:last-child {
  border-bottom: none;
}
.table-area > div:last-child > div {
  border-right: none;
}
.table-area > div > div input {
  width: 100%;
  border: none;
  font-size: 15px;
  outline: none;
  height: 90%;
}
.table-area > div > div textarea {
  background: transparent !important;
  height: 50px !important;
  font-size: 15px !important;
  padding: 0px !important;
  outline: none !important;
  border-radius: 0px !important;
}
.table-area > div > div textarea::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1100px) {
  .main-form-area-content {
    margin-bottom: 6rem;
  }
  .AddNewCurrentProgram .select-container,
  .IndividualSupportPlan .select-container,
  .BehaviourSupportPlan .select-container {
    flex-direction: column;
  }
  .AddNewCurrentProgram .select-container > *:first-child,
  .IndividualSupportPlan .select-container > *:first-child,
  .BehaviourSupportPlan .select-container > *:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper > * {
    width: 30% !important;
  }
}
@media screen and (max-width: 900px) {
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    align-items: flex-start;
  }
}
@media screen and (max-width: 950px) {
  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan .profile-information-content .profile-about,
  .BehaviourSupportPlan .profile-information-content .profile-about {
    width: 100% !important;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    flex-direction: row !important;
  }
}
@media screen and (max-width: 780px) {
  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan .profile-information-content .profile-about,
  .BehaviourSupportPlan.profile-information-content .profile-about {
    grid-template-columns: 1fr;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    grid-column: span 1 !important;
  }
}
@media screen and (max-width: 850px) {
  .AddClientInformation .main-form-area-content {
    grid-template-columns: 1fr;
  }
  .AddClientInformation .navigation-area ul li {
    text-align: left;
  }
}
@media screen and (max-width: 750px) {
  .AddClientInformation .pure-form-area .buttons-wrapper {
    flex-direction: column-reverse;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper #close-label,
  .AddClientInformation .pure-form-area .buttons-wrapper #Save-Template-label {
    margin-right: 0px;
    margin-top: 10px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper > * {
    min-width: 100%;
  }

  .select-wrapper {
    min-width: 100% !important;
  }
  .selecters-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .selecters-grid > * {
    grid-column: span 2 !important;
  }
}
@media screen and (max-width: 600px) {
  .selecters-grid {
    grid-template-columns: 1fr !important;
  }
  .selecters-grid > * {
    grid-column: span 1 !important;
  }
}

@media screen and (max-width: 525px) {
  .input-collection.d-flex {
    flex-direction: column;
  }
  .input-collection.d-flex > * {
    margin-right: 0px;
    width: 100%;
  }
  .input-collection.d-flex > .left-side {
    margin-right: 0px !important;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 470px) {
  .AddClientInformation .top-area-content a {
    position: static;
    margin-bottom: 0px;
    display: block;
  }

  .AddNewCurrentProgram .profile-information-content .profile-about,
  .IndividualSupportPlan.profile-information-content .profile-about,
  .BehaviourSupportPlan .profile-information-content .profile-about {
    min-width: 100% !important;
    grid-template-columns: 1fr !important;
  }
  .AddNewCurrentProgram .profile-information-content .profile-about > *,
  .IndividualSupportPlan .profile-information-content .profile-about > *,
  .BehaviourSupportPlan .profile-information-content .profile-about > * {
    flex-direction: column !important;

    min-width: 100% !important;
    width: 100% !important;
  }
  .AddNewCurrentProgram
    .profile-information-content
    .profile-about
    > *
    .input-wrapper,
  .IndividualSupportPlan
    .profile-information-content
    .profile-about
    > *
    .input-wrapper,
  .BehaviourSupportPlan
    .profile-information-content
    .profile-about
    > *
    .input-wrapper {
    width: 100%;
  }
  .profile-information-content .profile-about > div:first-child,
  .profile-information-content .profile-about > div:last-child {
    margin-top: 0px;
  }
  .AddClientInformation .profile-about .input-container {
    flex-direction: column;
  }
  .AddClientInformation .profile-about label {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    display: block;
  }
}
@media screen and (max-width: 370px) {
  .AddClientInformation .top-area-content h1 {
    font-size: 18px;
  }
  .AddClientInformation .main-heading p,
  .AddClientInformation .navigation-area ul li,
  .AddClientInformation .pure-form-area textarea,
  .AddClientInformation .pure-form-area label {
    font-size: 14px;
  }
  .AddClientInformation .main-heading img {
    width: 25px;
  }
  .AddClientInformation .pure-form-area .buttons-wrapper {
    flex-direction: column-reverse;
  }

  .AddClientInformation .main-form-area-content {
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 300px) {
  .AddClientInformation .main-heading p {
    text-align: center;
  }
}
