@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  scroll-behavior: smooth;
}
h4 {
  text-align: center;
  padding-bottom: 15px;
}
body,
button,
input {
  font-size: 80% !important;
}
body,
textarea,
button,
input,
a {
  font-family: "Rubik", sans-serif;
}
.fa-plus-square,
.trash-image {
  cursor: pointer !important;
}
label {
  cursor: pointer;
}

button {
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
}
button:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
iframe {
  display: none !important;
}
.show-notifications.hide {
  display: none;
}
