.assign-instructor-wrapper {
  padding: 5rem 0rem;
  background-color: #f2f2f2;
}
.assign-instructor-content {
  width: 85%;
  margin: 0rem auto;
}
.assign-instructor {
  min-height: 400px;
  padding: 2rem 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-gap: 2rem;
  margin-bottom: 4rem;
}
.assign-instructor .left-side,
.assign-instructor .right-side,
.assign-instructor .middle-side {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.assign-instructor .left-side .intructor-info,
.assign-instructor .right-side .image-wrapper {
  flex: 1;
}
.assign-instructor .left-side .name {
  font-size: 22px;
  font-weight: 500;
}
.assign-instructor .left-side .email {
  font-size: 16px;
  color: #a6a6a6;
}
.assign-instructor .left-side button {
  width: 170px;
  height: 40px;
  background-color: #4dbfec;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}

.assign-instructor .right-side {
  align-items: center;
}
.assign-instructor .right-side > p {
  color: #b0b0b0;
  text-align: center;
  font-size: 16px;
}
.assign-instructor .right-side .image-wrapper {
  width: 70%;
}
.assign-instructor .right-side .image-wrapper img {
  width: 100%;
}
.assign-instructor .middle-side > h1 {
  font-size: 17px;
  color: #4dbfec;
  font-weight: 500;
}
.assign-instructor .middle-side ul {
  width: 100%;
  margin-top: 20px;
  height: 280px;
  overflow: auto;
}

.assign-instructor .middle-side ul::-webkit-scrollbar,
.edit-details > div ul::-webkit-scrollbar {
  width: 7px;
}
.assign-instructor .middle-side ul::-webkit-scrollbar-thumb,
.edit-details > div ul::-webkit-scrollbab-thumb {
  width: 100%;
  background-color: #6db8e3;
  border-radius: 99px;
}
.assign-instructor .middle-side ul li,
.edit-details > div ul li {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
}
.edit-details {
  position: relative;
}
.edit-details > * {
  position: relative;
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}
.arrow-box {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  z-index: 222;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 20px;
}
.arrow-box::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  background-color: #ddd;
}
.arrow-box > * {
  padding: 1rem;
  cursor: pointer;
  pointer-events: none;
}
.arrow-box > * i {
  font-size: 1.5rem;
  color: #a6a6a6;
}

.arrow-box > *.active i {
  color: #4dbfec;
}
.arrow-box > *.active {
  cursor: pointer;
  pointer-events: unset;
}
.assign-instructor .middle-side ul li {
  padding-right: 3rem;
  position: relative;
}
.Assigned-wrapper .mask {
  display: none;
}

.Assigned-wrapper ul .fa-times-circle {
  color: #ee0000;
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: none;
}

.edit-details > div ul li input:checked + label + .fa-times-circle {
  display: block !important;
}
.clients-id-wrapper .information li {
  width: 100%;
  display: block;

  padding: 0px !important;
}
.clients-id-wrapper .information li div label {
  padding: 1.5rem;
  cursor: pointer !important;
}
.clients-id-wrapper .information li div label:hover {
  background-color: #f6f8fa !important;
}
.clients-id-wrapper .information li div label .name {
  font-size: 1.1rem;
  flex: 1;
}
.assign-instructor .middle-side ul li.active {
  background-color: #f6f8fa;
}
.assign-instructor .middle-side ul li.active .fa-times-circle,
.Assigned-wrapper ul li.active .fa-times-circle {
  color: #c62b2b;
  cursor: pointer;
}
/* time-circle */
.assign-instructor .middle-side ul li p.name i {
  color: #a6a6a6;
  margin-right: 10px;
}
.assign-instructor .middle-side ul li p.name,
.edit-details > div ul li p.name {
  font-size: 15px;
  font-weight: 500;
}
.assign-instructor .middle-side ul li p:nth-child(2),
.edit-details > div ul li p:nth-child(2) {
  font-size: 14px;
}
.assign-instructor .middle-side ul li.time-circle,
.Assigned-wrapper ul li.active {
  background-color: #f6f8fa;
  border-radius: 10px;
  border-color: #ebeff3;
}

/* edit-details */
.edit-details {
  background-color: #f4f6f9;
  padding: 2rem;
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.edit-details > div h1 {
  font-size: 16px;
  font-weight: 500;
  padding-left: 2rem;
}

.edit-details > div ul {
  height: 250px;
  background-color: #fff;
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  border: 1px solid #c8d2dc;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: auto;
}
.edit-details > div ul li {
  padding-left: 2rem;
  padding-right: 2rem;
}
.edit-details > div:last-child ul {
  border-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 0px;
}
.edit-details > div ul .custom-checkbox {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #fff;
  border: 1px solid #c8d2dc;
}
.edit-details > div ul label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.Assigned-wrapper ul li {
  padding-right: 3rem !important;
  position: relative;
}

.edit-details.active {
  display: grid;
}
.edit-details > div ul label p.name {
  flex: 1;
}
.edit-details > div ul li input {
  display: none;
}
.edit-details > div ul li input:checked + label .custom-checkbox {
  background-color: #4dbfec;
}
.edit-details .buttons-wrapper {
  margin-top: 2rem;
  display: flex;
  grid-column: span 2;
  align-items: center;
  justify-content: center;
}
.edit-details .buttons-wrapper button:first-child {
  background-color: #c8d2dc;
  margin-right: 10px;
}
.edit-details .buttons-wrapper button {
  width: 180px;
  height: 50px;
  background-color: #4dbfec;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}
/* .profile-information-content */
@media screen and (max-width: 750px) {
  .consent-form-content h1,
  .Assessment-form-content form .right-side h1 {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1100px) {
  .AssignInstructor .profile-information-content {
    padding: 3rem 0rem;
  }
  .assign-instructor {
    grid-template-columns: 1fr;
  }
  .assign-instructor > .left-side {
    order: 2;
  }
  .assign-instructor > .middle-side {
    order: 3;
  }
  .assign-instructor > .right-side {
    order: 1;
  }
  .assign-instructor .left-side button {
    margin-top: 1rem;
  }
  .assign-instructor .right-side {
    align-items: flex-start;
  }
  .assign-instructor .right-side .image-wrapper img {
    width: 150px;
  }

  .assign-instructor .right-side > p {
    margin-top: 10px;
  }
  .edit-details {
    grid-column: span 1;
    order: 4;
  }
}
@media screen and (max-width: 850px) {
  .edit-details {
    grid-template-columns: 1fr !important;
    grid-gap: 1rem;
  }
  .edit-details .buttons-wrapper {
    grid-column: span 1;
  }
  .edit-details > div h1 {
    padding-left: 0rem;
  }
}
@media screen and (max-width: 620px) {
  .buttons-wrapper > * {
    width: 100% !important;
  }
}
@media screen and (max-width: 470px) {
  .assign-instructor-content {
    width: 95%;
  }
  .edit-details > div ul li {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 370px) {
  .edit-details {
    padding: 2rem 1rem;
  }
  .edit-details > div ul li {
    padding-left: 1rem;
    padding-right: 1rem;
    padding: 0.7rem 1rem !important;
  }
  .edit-details > div ul label {
    flex-direction: column;
    align-items: flex-start;
  }
  .edit-details > div ul label > * + * {
    margin-top: 5px;
  }
}
@media screen and (max-width: 370px) {
  .edit-details .buttons-wrapper {
    flex-direction: column;
  }
  .edit-details .buttons-wrapper > *:first-child {
    margin-right: 0rem !important;
    margin-bottom: 10px;
  }
}
