.Login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Login > img {
  width: 230px;
}
.Login form {
  margin-top: 4rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login form > * + * {
  margin-top: 1.8rem;
}
.Login form .input-wrapper {
  background-color: #f6f8fa;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  border-radius: 15px;
  padding: 0rem 1rem;
}
.Login form .input-wrapper i {
  color: #c2cad2;
}
.Login form .input-wrapper input {
  flex: 1;
  height: 80%;
  width: 100%;
  border: none;
  outline: none;
  margin-left: 20px;
  color: #525252;
  font-weight: 400;
  font-size: 15px;
  background-color: transparent;
}
.Login form input[type="submit"] {
  background-color: #4dbfec;
  border: none;
  width: 150px;
  height: 43px;
  outline: none;
  font-size: 15px;
  border-radius: 99px;
  margin-top: 2.5rem;
  color: #fff;
  cursor: pointer;
}

.login-button {
  background-color: #4dbfec;
  border: none;
  width: 150px;
  height: 43px;
  outline: none;
  font-size: 15px;
  border-radius: 99px;
  margin-top: 2.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-divider {
  color: #b3b3b3;
}

.sign-up-link {
  color: #0593e6;
}

@media screen and (max-width: 500px) {
  .Login form {
    width: 90%;
    margin: 0rem auto;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 450px) {
  .Login > img {
    width: 60%;
  }
}
