.notification-wrapper {
  background-color: #f2f2f2;
  padding: 4rem 0rem;
}

.notification-wrapper-content {
  background-color: #fff;
  padding: 1rem;
  padding-bottom: 20rem;
  border-radius: 15px;
}
.notification-wrapper-content .top-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.notification-table-top > div > h4 {
  text-align: left;
  padding-bottom: 0px;
}
.drop-down-area {
  position: relative;
}
.drop-down-area .checkbox-icon-wrapper {
  cursor: pointer;
}
.drop-down-area ul {
  position: absolute;
  top: 96%;
  left: -20px;
  display: none;
  background-color: #fff;
  z-index: 222;
  width: 200px;
  border-radius: 15px;
  border: 1px solid #ddd;
}
.drop-down-area ul.active {
  display: block;
}
.drop-down-area ul li:first-child label {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.drop-down-area ul li:last-child label {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.drop-down-area ul li input:checked + label {
  background-color: #4dbfec;
  color: #fff;
}
.drop-down-area ul li input {
  display: none;
}
.drop-down-area ul li label {
  padding: 1rem;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.notification-wrapper-content .top-area > * {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notification-wrapper-content .top-area > * h3 {
  flex: 1;
  font-weight: 500;
  font-size: 15px;
  margin: 0rem 10px;
}
.notification-wrapper-content .top-area > * {
  background-color: #f4f6f9;
  padding: 1rem;
}
.notification-wrapper-content .top-area > *.active {
  background-color: #fff;
}
.notification-wrapper-content .top-area > *.active h3,
.notification-wrapper-content .top-area > *.active i {
  color: #4dbfec;
}

.notification-wrapper-content .top-area > *.active .badge {
  background-color: #d9f2fb;
  color: #4dbfec;
}
/* D9F2FB */
.notification-wrapper-content .top-area > * .badge {
  padding: 0.3rem 1rem;
  background-color: #d6dfe8;
  border-radius: 99px;
  font-size: 15px;
}
.notification-wrapper .notification-table-area {
  margin-top: 3rem;
}
.notification-wrapper .notification-table-area input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.notification-wrapper .notification-table-area .notification-table-top {
  padding: 0rem 1.5rem;
}

.notification-wrapper .notification-table-area .notification-table-top,
.notification-wrapper .notification-table-area .notification-table-body-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
}

.notification-wrapper .notification-table-area .notification-table-top .col-1,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-1,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  label {
  display: flex;
  align-items: center;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  + * {
  margin-left: 20px;
}
.notification-wrapper
  .notification-table-area
  .notification-table-top
  .col-1
  > *
  + *,
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-1
  > *
  + * {
  margin-left: 10px;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  form {
  grid-column: span 4;
  display: none;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  form.active {
  display: block;
}
.text-area-wrapper {
  position: relative;
}
.text-area-wrapper input {
  display: none;
}
.text-area-wrapper label {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  background-color: #c8d2dc;
  width: 65px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 45px;
  color: #fff;
  border-radius: 10px;
}
.text-area-wrapper > i {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #c2cad2;
}

.notification-table-body-row i.fa-pen {
  color: #c2cad2;
}

.notification-table-body-row form textarea {
  text-indent: 30px;
  width: 100%;
  height: 165px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-top: 10px;
  background-color: #f6f8fa;
  border-radius: 15px;

  resize: none;
  border: none;
  font-family: "Rubik", sans-serif;
  outline: none;

  padding: 1rem;
}

.notification-table-body-area {
  margin-top: 1rem;
  border-radius: 15px;
  border: 1px solid #c8d2dc;
}

.notification-table-body-area .notification-table-body-row {
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #c8d2dc;
  position: relative;
}
.notification-table-body-area .notification-table-body-row:last-child {
  border-bottom: none;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row:first-child.active {
  border-top-left-radius: 10px;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row.active::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #4dbfec;
  left: 0;
  border-top-left-radius: 15px;
  top: 0;
}
.notification-wrapper
  .notification-table-area
  .notification-table-body-row.active
  .col-1
  p {
  font-weight: 600;
}

.notification-wrapper
  .notification-table-area
  .notification-table-body-row
  .col-4
  > *
  label
  p {
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .notification-wrapper {
    overflow: auto;
  }
  .notification-wrapper-content {
    min-width: 1160px;
    margin: 0rem 2rem;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1100px) {
  .profile-information-content {
    padding: 3rem 0rem;
  }
}
