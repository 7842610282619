.ResultNavigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  list-style: none;
}
.ResultNavigation a {
  background-color: #f4f6f9;

  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
  border-top-right-radius: 10px;
}
.ResultNavigation a.active {
  background-color: transparent;
  color: #4dbfec;
}
.ResultDropDown {
  position: relative;
}
.ResultDropDown .head {
  border: 1px solid #c8d2dc;
  padding: 1.2rem 1rem;
  font-size: 18px;
  border-radius: 10px;
  color: #525252;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.ResultDropDown .head i {
  transition: 0.3s ease all;
}
.ResultDropDown ul {
  border: 1px solid #c8d2dc;
  border-radius: 16px;
  background-color: #fff;
  position: absolute;
  width: 100%;
  transform: translateY(20px);
  z-index: -2;
  transition: 0.3s ease all;
  opacity: 0;
}
.ResultDropDown ul li a {
  padding: 1.5rem 1rem;
  color: #000000;
  display: block;
  border-radius: 7px;
  font-size: 15px;
}
.ResultDropDown ul li a:hover {
  background-color: #4dbfec;
  color: #fff;
}
.ResultDropDown:hover .head i {
  transform: rotate(180deg);
}
.ResultDropDown:hover ul {
  transform: translateY(0px);
  z-index: 2;
  opacity: 1;
}
@media screen and (max-width: 1000px) {
  .ResultNavigation {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  .ResultNavigation a {
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .ResultNavigation {
    grid-template-columns: 1fr;
  }
}
