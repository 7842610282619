.behaviour-form-area {
  background: #f2f2f2;
  padding: 4rem 0rem;
}
.behaviour-form-content-area {
  width: 85%;
  background: #fff;
  padding: 3rem;
  border-radius: 10px;
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: 250px 200px 100px 60px;
}
.behaviour-form-content-area .select-container {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-top: 0px;
}
.behaviour-form-content-area .select-container > * {
  flex: 0;
}
.behaviour-form-content-area .select-container label,
.behaviour-form-content-area .input-collection label {
  margin-bottom: 10px;
  display: block;
}

.behaviour-form-content-area > *:nth-child(2),
.behaviour-form-content-area > *:nth-child(4) {
  grid-column: span 2;
}
.behaviour-form-content-area > *:nth-child(5),
.behaviour-form-content-area > *:nth-child(6),
.behaviour-form-content-area > *:nth-child(7) {
  grid-column: span 3;
}

.behaviour-form-content-area .input-collection textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 10px;
  padding: 1rem;
  background: #fafbfc;
  border: 1px solid #e6e6e6;
  resize: none;
  font-family: "Rubik", sans-serif;
}
.behaviour-form-content-area .input-wrapper {
  height: 55px !important;
  border: 1px solid #e6e6e6;
}
.behaviour-form-content-area .input-wrapper > input {
  width: 100%;
  flex: 1;
  margin-left: 10px;
  height: 90%;
  border: none;
  outline: none;
  background: transparent;
}
.behaviour-form-content-area .checkbox-container {
  margin-top: 1.5rem;
}
.behaviour-form-content-area .checkbox-container > * + * {
  margin-left: 4rem;
}
.behaviour-form-content-area .checkbox-container,
.behaviour-form-content-area .checkbox-container label {
  display: flex;
  align-items: center;
}
.behaviour-form-content-area .button-wrapper {
  display: flex;
  align-items: center;

  justify-content: center;
}
.behaviour-form-content-area .button-wrapper input {
  display: none;
}
.behaviour-form-content-area .button-wrapper label {
  width: 180px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4dbfec;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}

.behaviour-form-content-area .added-behaviour-wrapper p {
  margin-bottom: 2rem;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-wrapper {
  background: #f4f6f9;
  padding: 2.5rem 2rem;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-content {
  height: 350px;
  overflow: auto;
  background: #fff;
  padding: 0.6rem 1.5rem;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content::-webkit-scrollbar {
  width: 7px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: #6db8e3;
  border-radius: 99px;
}

.behaviour-form-content-area .added-behaviour-wrapper .scroll-content li {
  padding: 1.5rem 1rem;
  border-radius: 15px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li.active {
  background: #f6f8fa;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li.active {
  position: relative;
}

.delete-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  color: red;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li
  .delete-icon {
  cursor: pointer;
}
.behaviour-form-content-area .added-behaviour-wrapper .scroll-content li {
  position: relative;
}
.behaviour-form-content-area .added-behaviour-wrapper .scroll-content label {
  border-radius: 20px;
}

.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  label:hover {
  background-color: #ddd !important;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  input:checked
  + label {
  display: block;
  background-color: #ddd !important;
}
.behaviour-form-content-area
  .added-behaviour-wrapper
  .scroll-content
  li:hover
  .delete-icon {
  color: #c62b2b;
}
.behaviour-form-content-area .buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.behaviour-form-content-area .buttons-wrapper input {
  display: none;
}
.behaviour-form-content-area .buttons-wrapper > * + * {
  margin-left: 2rem;
}
.behaviour-form-content-area .buttons-wrapper > label,
.behaviour-form-content-area .buttons-wrapper > a {
  width: 180px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 2px solid #4cbfed !important;
  color: #4cbfed !important;
  font-weight: 700 !important;
  transition: 0.3s ease all;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
}
.behaviour-form-content-area .buttons-wrapper > label:hover,
.behaviour-form-content-area .buttons-wrapper > a:hover {
  background-color: #4cbfed !important;
  border: 2px solid #4cbfed !important;
  color: #fff !important;
}
.behaviour-form-content-area .buttons-wrapper > .cancel-label {
  background: #c8d2dc;
  margin-left: 0px;
}
@media screen and (max-width: 1100px) {
  .behaviour-form-content-area {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 780px) {
  .behaviour-form-content-area {
    grid-template-rows: 40px 200px 50px 50px 50px 50px;
  }
  .behaviour-form-content-area > * {
    grid-column: span 3;
  }
  .behaviour-form-content-area > *:nth-child(2),
  .behaviour-form-content-area > *:nth-child(4) {
    grid-column: span 3;
  }
  .behaviour-form-content-area > *:nth-child(5),
  .behaviour-form-content-area > *:nth-child(6),
  .behaviour-form-content-area > *:nth-child(7) {
    grid-column: span 3;
  }
  .behaviour-form-content-area .buttons-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .behaviour-form-content-area .buttons-wrapper > *,
  .behaviour-form-content-area .button-wrapper label {
    width: 100% !important;
  }
  .behaviour-form-content-area .buttons-wrapper > * + * {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .behaviour-form-content-area {
    width: 90%;
    padding: 1.5rem;
  }
  .behaviour-form-content-area .added-behaviour-wrapper .scroll-wrapper {
    padding: 0.5rem 0.5rem;
  }
}
@media screen and (max-width: 420px) {
  .behaviour-form-content-area .checkbox-container > * + * {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 300px) {
  .behaviour-form-content-area .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .behaviour-form-content-area .checkbox-container > * + * {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
