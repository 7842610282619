.NotesBodyArea {
  background-color: #f2f2f2;
  padding: 3rem 0rem;
  padding-bottom: 10rem;
}

.NotesBodyAreaBox {
  padding: 2rem 2rem;
  width: 90%;
  margin: 0rem auto;
  background-color: #fff;
  border-radius: 10px;
}

.NotesBodyAreaBoxContentArea {
  display: grid;
  grid-template-columns: 24% 1fr;
  grid-gap: 2rem;
  margin-top: 3rem;
}
.dropdownWrapper {
  margin-bottom: 2rem;
}
.dropdownWrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
}

/* NotesWritingAea */
.NotesWritingAea,
.RawDataCommon {
  border: 1px solid #707070;
  border-radius: 20px;
}
.NotesWritingAea .top {
  position: relative;
  text-align: center;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #707070;
}
.RawDataCommon .top {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #707070;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.NotesWritingAea .top h3 {
  font-weight: 500;
  font-size: 22px;
}
.NotesWritingAea .top button,
.buttonWrapper button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-color: #4dbfec;
  border: none;
  color: #fff;
  padding: 0.8rem 1.4rem;
  border-radius: 99px;
  cursor: pointer;
  font-size: 14px;
}

.instructor_observerd {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
}
.instructor_observerd p {
  font-weight: 500;
  font-size: 18px;
}
.instructor_observerd input {
  width: 100%;
  flex: 1;
  margin-left: 1rem;
  font-size: 18px;
  border: none;
  outline: none;
}

.textareaWrapper {
  padding: 1rem;
}
.textareaWrapper textarea {
  width: 100%;
  height: 550px;
  font-size: 18px;
  border: none;
  resize: none;
  outline: none;
}

/* buttonWrapper */
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonWrapper > * + * {
  margin-left: 1rem;
}
.buttonWrapper button {
  position: static;
  min-width: 160px;
  transform: translateY(0%);
}

.NotesBodyAreaBoxContentArea > div:first-child {
  display: flex;
  flex-direction: column;
  height: 50%;

  justify-content: space-between;
}

/* RawDataCommon */
.RawDataBody ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;

  align-items: center;
  border-bottom: 1px solid #707070;
}
.RawDataBody ul:last-child {
  border: none;
}
.Therapist .RawDataBody ul {
  grid-template-columns: 1fr 1fr 2fr;
}
.RawDataBody > ul li:last-child > p {
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
}
.RawDataBody ul li {
  padding: 1.5rem 1rem;
  border-right: 1px solid #707070;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.RawDataBody ul li ul {
  display: flex;
  border: none;

  width: 90%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
}
.RawDataBody ul li ul li {
  padding: 0px;
  border: none;
  border-bottom: 0px;
}
.RawDataBody ul li:last-child {
  border-right-width: 0px;
}
.RawDataBody ul li p:last-child {
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .NotesBodyAreaBoxContentArea {
    grid-template-columns: 1fr;
  }
  .NotesWritingAea .top {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .NotesWritingAea .top button {
    transform: translateY(0px);
    position: static;
  }

  .RawDataCommon {
    min-width: 700px;
  }
}
@media screen and (max-width: 800px) {
  .RawDataWritingArea {
    overflow: auto;
  }
}
@media screen and (max-width: 560px) {
  .NotesWritingAea .top {
    align-items: center;
    flex-direction: column;
  }
  .NotesWritingAea .top button {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .instructor_observerd {
    flex-direction: column;
    align-items: flex-start;
  }
  .instructor_observerd input {
    margin-left: 0px;
    margin-top: 10px;
  }
  .buttonWrapper button {
    min-width: 50%;
  }
}
@media screen and (max-width: 420px) {
  .buttonWrapper {
    flex-direction: column;
  }
  .buttonWrapper button {
    min-width: 100%;
  }
  .buttonWrapper > * + * {
    margin-left: 0px;
    margin-top: 10px;
  }
}
