/* src/Assets/style/css/signup.css */
.SignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.SignUp img {
  width: 150px;
  margin-bottom: 20px;
}

.form {
  width: 100%;
  max-width: 400px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-wrapper i {
  margin-right: 10px;
}

.input-wrapper input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sign-up-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.or-divider {
  margin: 20px 0;
  text-align: center;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}
