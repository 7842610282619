nav,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}
.required_Ast {
  color: #ee0000;
  font-size: 18px;
  margin-top: 7px;
  margin-right: 5px;
}
.w-90 {
  width: 90%;
  margin: 0rem auto;
}
.Header {
  border-bottom: 1px solid #ccced0;
  position: sticky;
  top: 0px;
  z-index: 4;
  background-color: #fff;
}
.noSticky {
  /* position: static; */
}
.header-content {
  padding: 0.5rem 0rem;
}
.header-content,
.header-content nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-content > img {
  width: 150px;
}
.main-header-nav > * + * {
  margin-left: 4rem;
}
.main-header-nav a {
  color: #515454;
  display: flex;
  align-items: center;
}
.main-header-nav a i {
  margin-right: 7px;
}
.main-header-nav a.active {
  color: #4dbfec !important;
}
.sign-out-button {
  background-color: #bec4c6;
  color: #fff;
  border: none;
  outline: none;
  width: 150px;
  height: 45px;
  border-radius: 99px;
  cursor: pointer;
  font-size: 18px;
}

@media screen and (max-width: 1100px) {
  .header-content nav {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1.5rem 2rem;
    background-color: #fff;
    width: 100%;
    z-index: 22;
  }
}
@media screen and (max-width: 540px) {
  .header-content > img {
    width: 120px;
  }
  .sign-out-button {
    height: 40px;
    font-size: 16px;
    width: 140px;
  }
  .header-content nav {
    padding: 1.5rem 2rem !important;
  }
}
@media screen and (max-width: 370px) {
  .sign-out-button {
    width: 110px;
    font-size: 15px;
  }
  .header-content > img {
    width: 100px;
  }
}
